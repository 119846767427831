import { useLazyQuery } from "@apollo/client";
import { at } from "lodash/fp";
import { useCallback } from "react";
import { CarState } from "types/cars";
import { CAPTURE_PAYMENT } from "../graphql/queries";
import { TripState } from "types/user";
import { tripEmission } from "libraries/emission";

type Props = {
  donation: number;
  distance: number;
  mobileNumber: string;
};

type ReturnType = {
  handleVippsPayment: (
    trip: TripState,
    car: CarState,
    numberOfTripsToDonate: number
  ) => void;
  loadingPayment: boolean;
};

const useHandleVippsPayment = ({
  donation,
  distance,
  mobileNumber,
}: Props): ReturnType => {
  const [capturePayment, { loading: loadingPayment }] =
    useLazyQuery(CAPTURE_PAYMENT);

  const handleVippsPayment = useCallback(
    async (trip: TripState, car: CarState, numberOfTripsToDonate: number) => {
      console.debug("[Component] Car Details - Vipps initiate payment");

      const { totalKgEmission } = tripEmission(car, trip.distanceMeters);

      const CO2FootprintInKg = (
        totalKgEmission * numberOfTripsToDonate
      ).toFixed();

      const { data } = await capturePayment({
        variables: {
          carId: car.id,
          tripId: trip.tripId,
          licensePlate: car.licensePlate,
          carBrand: car.carBrand,
          distance: parseFloat(distance.toFixed(2)),
          donationAmount: parseFloat(donation.toFixed(2)),
          co2FootprintInKg: parseFloat(CO2FootprintInKg),
          mobileNumber: mobileNumber,
          fallbackUrl: window.location.origin.toString(),
        },
      });

      const [url] = at("capturePayment.urlRedirect")(data);

      if (!url) {
        console.warn(
          "[Component] useHandleVippsPayment - Vipps capture initiation rejected",
          donation
        );
      } else {
        window.location.replace(String(url));
      }
    },
    [capturePayment, donation, distance, mobileNumber]
  );

  return {
    handleVippsPayment,
    loadingPayment,
  };
};

export default useHandleVippsPayment;
