import { useEffect, useState } from "react";
import { CONFIRM_SIGNIN } from "../../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { LinearProgress, Alert } from "@mui/material";
import { AppContainer } from "../base-app/App";
import { ROUTE } from "./AppRoutes";
import useAppNavigate from "../../hooks/useAppNavigate";

export const STORAGE_KEY_AUTH_TOKEN = "shed-token";
export const STORAGE_KEY_LOGIN_EMAIL = "login-email";
export const STORAGE_KEY_AUTH_REFRESH_TOKEN = "shed-refresh-token";
export const USER_ID = "uid";

const LoginMagicCallback = () => {
  const [alertLabel, setAlertLabel] = useState("Confirming credentials");
  const [confirmSignin, { loading }] = useLazyQuery(CONFIRM_SIGNIN);
  const navigateTo = useAppNavigate();

  useEffect(() => {
    console.log(`[Component] LoginCallback Process Magic Link`);
    const urlParams = window.location.href;
    const email = localStorage.getItem(STORAGE_KEY_LOGIN_EMAIL);

    if (!email) {
      console.warn(
        "[Component] LoginMagicCallback - Auth confirmation not processed on same device"
      );
      setAlertLabel("Please confirm the magic link on same device");
      return;
    }

    const fetchAuthToken = async () => {
      const { data } = await confirmSignin({
        variables: { email, urlParams },
      });

      if (data) {
        localStorage.setItem(STORAGE_KEY_AUTH_TOKEN, data.confirmSignin.token);
        localStorage.setItem(
          STORAGE_KEY_AUTH_REFRESH_TOKEN,
          data.confirmSignin.refreshToken
        );
        localStorage.setItem(USER_ID, data.confirmSignin.uid);

        navigateTo(ROUTE.HOME);
      } else {
        setAlertLabel("Error valdiating email, please try later");
      }
    };

    fetchAuthToken();
    // Only renders on page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContainer>
      <Alert severity="info">{alertLabel}</Alert>
      {loading && <LinearProgress color="info" />}
    </AppContainer>
  );
};

export default LoginMagicCallback;
