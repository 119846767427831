import { LoadingButton } from "@mui/lab";
import LogOutIcon from "@mui/icons-material/Logout";
import { ROUTE } from "../base-app/AppRoutes";
import useAppNavigate from "../../hooks/useAppNavigate";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import {
  STORAGE_KEY_AUTH_TOKEN,
  STORAGE_KEY_AUTH_REFRESH_TOKEN,
  STORAGE_KEY_LOGIN_EMAIL,
  USER_ID,
} from "../base-app/LoginMagicCallback";

const LogOutButton = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigateTo = useAppNavigate();

  const handleLogout = () => {
    localStorage.removeItem(STORAGE_KEY_LOGIN_EMAIL);
    localStorage.removeItem(USER_ID);
    localStorage.removeItem(STORAGE_KEY_AUTH_TOKEN);
    localStorage.removeItem(STORAGE_KEY_AUTH_REFRESH_TOKEN);

    navigateTo(ROUTE.MAP);
  };

  return isAuthenticated ? (
    <LoadingButton
      variant="contained"
      size="large"
      color="error"
      startIcon={<LogOutIcon />}
      onClick={handleLogout}
    >
      Logout
    </LoadingButton>
  ) : null;
};

export default LogOutButton;
