import { Divider, Typography } from "@mui/material";
import { truncate } from "lodash/fp";

const ProfileTerms = () => (
  <Divider textAlign="center">
    <Typography color="text.secondary" variant="caption">
      Version #{truncate({ length: 8 })(process.env.REACT_APP_VERSION ?? "")}
    </Typography>
  </Divider>
);
export default ProfileTerms;
