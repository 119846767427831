import {
  FormControl,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material";

import { styled } from "@mui/material/styles";

interface CustomOutlinedInputProps extends OutlinedInputProps {
  isMobileNumberError: boolean;
}

const StyledOutlinedInput = styled(
  ({ isMobileNumberError, ...otherProps }: CustomOutlinedInputProps) => (
    <OutlinedInput {...otherProps} />
  )
)<CustomOutlinedInputProps>(({ theme, isMobileNumberError }) => ({
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: isMobileNumberError ? "red" : "black",
  },
}));

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  mobileNumber: string;
  isMobileNumberError: boolean;
};

const PhoneNumberInput = ({
  onChange,
  mobileNumber,
  isMobileNumberError,
}: Props) => {
  return (
    <FormControl variant="outlined">
      <InputLabel>Phone number</InputLabel>
      <StyledOutlinedInput
        isMobileNumberError={isMobileNumberError}
        label="Phone Number"
        value={mobileNumber}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default PhoneNumberInput;
