import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { useAppDispatch } from "../../redux/hooks";
import { registerReferenceCar } from "../../redux/slices/shed/shedSlice";
import { CarState } from "../../types/cars";

type Props = {
  car: CarState;
  label: string;
};

const CarDetailsButton = ({ car, label }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <Button
      component={Link}
      to={`/h/car/${car.id}`}
      sx={{ maxWidth: 110 }}
      onClick={() => dispatch(registerReferenceCar(car))}
    >
      <Typography noWrap variant="button" align="center">
        {label}
      </Typography>
    </Button>
  );
};

export default CarDetailsButton;
