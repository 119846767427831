import { useSearchParams } from "react-router-dom";
import usePostMount from "hooks/core/usePostMount";
import {
  registerReferenceCar,
  registerReferenceTripLocation,
} from "redux/slices/shed/shedSlice";
import { TripShare } from "types/user";
import { CarGenericState } from "types/cars";
import { useAppDispatch } from "redux/hooks";
import genericCars from "libraries/generic.json";
import { genericCarToCar } from "libraries/car";
import { find } from "lodash/fp";

const useMapShareTripParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  usePostMount({
    handler: () => {
      const tripShare: TripShare = {
        genericVin: searchParams.get("genericVin") ?? "",
        fromLabel: searchParams.get("fromLabel") ?? "",
        fromCoordinates: searchParams.get("fromCoordinates") ?? ";",
        toLabel: searchParams.get("toLabel") ?? "",
        toCoordinates: searchParams.get("toCoordinates") ?? ";",
      };

      if (
        tripShare.genericVin &&
        tripShare.fromLabel &&
        tripShare.fromCoordinates &&
        tripShare.toLabel &&
        tripShare.toCoordinates
      ) {
        console.log("[Map] Shared trip consummed", tripShare);

        searchParams.delete("fromLabel");
        searchParams.delete("fromCoordinates");
        searchParams.delete("toLabel");
        searchParams.delete("toCoordinates");
        searchParams.delete("genericVin");
        searchParams.delete("licensePlate");
        setSearchParams(searchParams);

        const fromLatitude = Number(
          tripShare.fromCoordinates.split(";")[0] || 0
        );
        const fromLongitude = Number(
          tripShare.fromCoordinates.split(";")[1] || 0
        );

        const toLatitude = Number(tripShare.toCoordinates.split(";")[0] || 0);
        const toLongitude = Number(tripShare.toCoordinates.split(";")[1] || 0);

        dispatch(
          registerReferenceTripLocation({
            fromAddress: tripShare.fromLabel,
            fromLatitude,
            fromLongitude,
            toAddress: tripShare.toLabel,
            toLatitude,
            toLongitude,
          })
        );
        const genericCar = find(
          ({ genericVin }: CarGenericState) =>
            genericVin === tripShare.genericVin
        )(genericCars);

        genericCar &&
          dispatch(registerReferenceCar(genericCarToCar(genericCar)));
      }
    },
  });
};

export default useMapShareTripParams;
