import React from "react";
import { Backdrop, styled, Paper, Box, SwipeableDrawer } from "@mui/material";
import { grey } from "@mui/material/colors";

const DRAWER_BLEEDING = 56;
const DRAWER_PULLER_WIDTH = 30;

const PaperBackground = styled(Paper)(({ theme }) => ({
  top: -DRAWER_BLEEDING,
  visibility: "visible",
  right: 0,
  left: 0,
}));

const Puller = styled(Box)(({ theme }) => ({
  width: DRAWER_PULLER_WIDTH,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: `calc(50% - ${DRAWER_PULLER_WIDTH / 2}px)`,
}));

type Props = {
  children: React.ReactElement;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
};

const AppDrawer = ({ children, isOpen, onClose, onOpen }: Props) => (
  <>
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer - 1 }}
      open={isOpen}
      onClick={onClose}
    />
    <SwipeableDrawer
      anchor={"bottom"}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
        BackdropProps: {
          style: { backgroundColor: "transparent" },
        },
      }}
      onOpen={onOpen}
      open={isOpen}
    >
      <PaperBackground elevation={2}>
        <Puller />
        {children}
      </PaperBackground>
    </SwipeableDrawer>
  </>
);

export default AppDrawer;
