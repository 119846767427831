import { useEffect, useRef, useContext } from "react";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { MapContext } from "../providers/MapProvider";

const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a: any, b: any) => {
    try {
      if (
        isLatLngLiteral(a) ||
        a instanceof google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof google.maps.LatLng
      ) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
      }
    } catch (error: unknown) {
      console.warn(
        "[useDeepCompareEffectForMaps] Dependecy equality comparison error",
        error
      );
    }

    // use fast-equals for other objects
    return deepEqual(a, b);
  }
);

const useDeepCompareMemoize = (value: any) => {
  const ref = useRef();
  const { initialized } = useContext(MapContext);
  if (!initialized || !deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

const useDeepCompareEffectForMaps = (
  callback: React.EffectCallback,
  dependencies: any[]
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

export default useDeepCompareEffectForMaps;
