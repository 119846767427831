import { useSelector } from "react-redux";
import ForestTree from "../core/ForestTree";
import { flow, sum, map } from "lodash/fp";
import {
  selectMyTrips,
  selectMyPayments,
  selectMyCars,
} from "../../redux/slices/shed/shedSlice";
import { Typography, Box, LinearProgress } from "@mui/material";
import ParkIcon from "@mui/icons-material/Park";
import { styled } from "@mui/material/styles";
import { TripState } from "types/user";
import { CarState } from "types/cars";

import { tripEmission } from "libraries/emission";

const paymentsSumC02 = flow(map("co2Footprint"), sum);

const totalTripsSumCO2 = (myTrips: TripState[], myCars: CarState[]): number => {
  return flow(
    map((trip: TripState) => {
      const tripCar = myCars.find((car) => car.id === trip.carId);

      const { totalKgEmission } = tripEmission(
        tripCar || null,
        trip.distanceMeters
      );

      const tripEmissionsToKgKm = totalKgEmission * trip.commuteFrequency;

      return tripEmissionsToKgKm;
    }),
    sum
  )(myTrips);
};

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  "& .MuiLinearProgress-bar": {
    borderRadius: 5,
    backgroundImage: "linear-gradient(45deg, #0198E1 30%, #32CD32 90%)",
  },
}));

const TREE_KG_CO2_YEARLY_ABSORB = 25;

const ProfileDashboard = () => {
  const myPayments = useSelector(selectMyPayments);
  const myTrips = useSelector(selectMyTrips);
  const myCars = useSelector(selectMyCars);

  const paymentCO2 = paymentsSumC02(myPayments);
  const tripsCO2: number = totalTripsSumCO2(myTrips, myCars);

  const paymentsTrees = paymentCO2 / TREE_KG_CO2_YEARLY_ABSORB;
  const tripsTrees = tripsCO2 / TREE_KG_CO2_YEARLY_ABSORB;

  return (
    <>
      <Box>
        <Typography variant="h6" gutterBottom>
          Donations to the Rainforest Foundation
        </Typography>

        <Box display="flex" alignItems="center" mb={2} mt={2}>
          <ParkIcon sx={{ color: "green" }} />
          <Typography flexGrow={1} ml={1}>
            Your donations in trees
          </Typography>

          <Typography>
            {paymentsTrees + " of " + tripsTrees.toFixed(2)}
          </Typography>
        </Box>
        <StyledLinearProgress
          variant="determinate"
          value={paymentsTrees === 0 ? 0 : (paymentsTrees / tripsTrees) * 100}
        />
      </Box>
      <Typography mt={2} color="primary">
        {`You have donated  ${(paymentsTrees - tripsTrees).toFixed(
          2
        )} fewer trees than the CO2 that is released from your trips`}
      </Typography>
      <Typography
        my={2.5}
        variant="h5"
        color="text.secondary"
        textAlign="center"
      >
        Your Forest
      </Typography>
      <ForestTree
        treeCount={tripsTrees}
        donated={paymentsTrees}
        leftToDonate={tripsTrees}
      />
    </>
  );
};

export default ProfileDashboard;
