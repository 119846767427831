import { useState, useEffect, useContext, useRef, RefObject } from "react";
import { MapContext } from "../../providers/MapProvider";
import { GoogleMap } from "../../types/map";
import { useAppSelector } from "../../redux/hooks";
import useDeepCompareEffectForMaps from "../../hooks/useDeepCompareEffectForMaps";
import { selectReferenceItinerary } from "../../redux/slices/shed/shedSlice";

type DirectionRenderer = google.maps.DirectionsRenderer;
type GoogleMapOptions = google.maps.MapOptions;

type Props = {
  mapWrapperReference: RefObject<HTMLDivElement | null>;
  onMapInitialized: (googleMap: GoogleMap) => void;
  googleMapOptions: GoogleMapOptions;
};

const useMapRenderer = ({
  onMapInitialized,
  mapWrapperReference,
  googleMapOptions,
}: Props) => {
  const { initialized: mapInitialized, maps } = useContext(MapContext);
  const googleMapRef = useRef<GoogleMap | null>(null);
  const [directionsRenderer, setDirectionRenderer] =
    useState<DirectionRenderer | null>(null);
  const referenceItinerary = useAppSelector(selectReferenceItinerary);

  // Google map binding initializer
  useEffect(() => {
    if (!(mapWrapperReference?.current && mapInitialized && maps)) {
      return;
    }

    const mapOptions = {
      gestureHandling: "greedy" as google.maps.GestureHandlingOptions,

      // include any other map options you need here
    };
    const googleMap = new maps.Map(mapWrapperReference.current, mapOptions);
    const mapDirectionsRenderer = new google.maps.DirectionsRenderer();

    mapDirectionsRenderer.setMap(googleMap);
    setDirectionRenderer(mapDirectionsRenderer);

    googleMapRef.current = googleMap;
    onMapInitialized(googleMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapWrapperReference, maps, mapInitialized]);

  // Google map binding generic synchronizer
  useDeepCompareEffectForMaps(() => {
    mapInitialized &&
      googleMapRef &&
      googleMapRef.current &&
      googleMapRef.current.setOptions(googleMapOptions);
  }, [googleMapOptions, mapInitialized]);

  // Google Map binding direction synchronizer
  useEffect(() => {
    // Synchronize zoom bounds
    googleMapRef &&
      googleMapRef.current &&
      referenceItinerary &&
      referenceItinerary.bounds &&
      googleMapRef.current.fitBounds(referenceItinerary.bounds);

    // Synchronize path rendering
    googleMapRef &&
      googleMapRef.current &&
      referenceItinerary &&
      directionsRenderer &&
      referenceItinerary.googleDirection &&
      directionsRenderer.setDirections(referenceItinerary.googleDirection);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMapRef, referenceItinerary]);
};

export default useMapRenderer;
