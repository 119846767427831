import { useCallback } from "react";
import { Box } from "@mui/material";
import {
  selectToggleDrawer,
  registerMapToggleValue,
} from "../../redux/slices/shed/shedSlice";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import AppDrawer from "components/base-app/AppDrawer";

const MapDrawer = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector(selectToggleDrawer);

  const openDrawer = useCallback(
    () => dispatch(registerMapToggleValue(true)),
    [dispatch]
  );

  const closeDrawer = useCallback(
    () => dispatch(registerMapToggleValue(false)),
    [dispatch]
  );

  return (
    <AppDrawer
      isOpen={Boolean(isDrawerOpen)}
      onClose={closeDrawer}
      onOpen={openDrawer}
    >
      <Box />
    </AppDrawer>
  );
};

export default MapDrawer;
