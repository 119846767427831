import { useCallback } from "react";
import { TripState } from "types/user";
import useSaveTrip from "hooks/trips/useSaveTrip";

type Props = {
  trip: TripState;
};

type ReturnType = {
  handleSaveFrequencyToTrip: (commuteFrequency: number) => void;
  loading: boolean;
};

const useSaveFrequencyToTrip = ({ trip }: Props): ReturnType => {
  const { handleSaveTrip, loading } = useSaveTrip();

  const handleSaveFrequencyToTrip = useCallback(
    async (commuteFrequency: number) => {

      await handleSaveTrip({
        ...trip,
        commuteFrequency,
      });
    },
    [handleSaveTrip, trip]
  );

  return { handleSaveFrequencyToTrip, loading };
};

export default useSaveFrequencyToTrip;
