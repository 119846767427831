import { MutableRefObject, useEffect, useContext } from "react";
import { MapContext } from "../../providers/MapProvider";

export type Autocomplete = google.maps.places.Autocomplete;

type Props = {
  referenceBinding: MutableRefObject<HTMLInputElement | null>;
  onInitialized: (autocomplete: Autocomplete) => void;
  onPlaceChange: (autocomplete: Autocomplete) => void;
};

type ReturnType = void;

const useAutocomplete = ({
  referenceBinding,
  onInitialized,
  onPlaceChange,
}: Props): ReturnType => {
  const { initialized, maps } = useContext(MapContext);

  useEffect(() => {
    if (!(initialized && maps && referenceBinding?.current)) {
      return;
    }
    const autocomplete = new maps.places.Autocomplete(referenceBinding.current);
    onInitialized(autocomplete);

    autocomplete.addListener("place_changed", () =>
      onPlaceChange(autocomplete)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, maps, referenceBinding]);
};

export default useAutocomplete;
