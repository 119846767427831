import MapMarker from "./MapMarker";

type Props = {
  lat: number;
  lng: number;
};

const MapPOI = ({ lat, lng }: Props) => <MapMarker position={{ lat, lng }} />;

export default MapPOI;
