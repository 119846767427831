import { useCallback } from "react";
import { useAppSelector } from "redux/hooks";
import { registerReferenceTripFrequency } from "redux/slices/shed/shedSlice";
import { useAppDispatch } from "redux/hooks";
import { selectReferenceTrip } from "redux/slices/shed/shedSlice";

type ReturnType = {
  commuteFrequency: number;
  increaseFrequency: () => void;
  decreaseFrequency: () => void;
};

const useReferenceTripCommuteFrequency = (): ReturnType => {
  const dispatch = useAppDispatch();
  const trip = useAppSelector(selectReferenceTrip);

  const handleIncreaseFrequency = useCallback(
    () => dispatch(registerReferenceTripFrequency(trip.commuteFrequency + 1)),
    [dispatch, trip]
  );

  const handleDecreaseFrequency = useCallback(
    () => dispatch(registerReferenceTripFrequency(trip.commuteFrequency - 1)),
    [dispatch, trip]
  );

  return {
    commuteFrequency: trip.commuteFrequency,
    increaseFrequency: handleIncreaseFrequency,
    decreaseFrequency: handleDecreaseFrequency,
  };
};

export default useReferenceTripCommuteFrequency;
