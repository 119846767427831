import { useState, useMemo, useEffect } from "react";
import {
  OperationVariables,
  QueryHookOptions,
  useQuery,
  ApolloError,
  DocumentNode,
} from "@apollo/client";
import {
  STORAGE_KEY_AUTH_TOKEN,
  STORAGE_KEY_AUTH_REFRESH_TOKEN,
} from "../components/base-app/LoginMagicCallback";

type RefreshPair = {
  token: string;
  refreshToken: string;
};

const getNewToken = async (
  refreshToken: string
): Promise<RefreshPair | null> => {
  console.log("[Hook] useAuthQuery - Fetch GetNewToken");
  try {
    const response = await fetch(
      `${process.env.REACT_APP_REST_URL}/v1/auth/refresh`,
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
          refreshToken,
        }),
      }
    );
    if (!response.ok) {
      throw new Error(`[${response.status}] Fetch REST auth refresh error`);
    }
    const refreshPair = (await response.json()) as RefreshPair;
    console.log("[Hook] useAuthQuery - Store new token");
    localStorage.setItem(STORAGE_KEY_AUTH_TOKEN, refreshPair.token);
    localStorage.setItem(
      STORAGE_KEY_AUTH_REFRESH_TOKEN,
      refreshPair.refreshToken
    );
    return refreshPair;
  } catch (error) {
    console.error("[Hook] useauthQuery - GetNewToken fetch error", error);
    return null;
  }
};

const useAuthQuery = <T>(
  graphqlQuery: DocumentNode,
  options?: QueryHookOptions<T, OperationVariables>
): {
  loading: boolean;
  data: T | undefined;
  error: ApolloError | undefined;
} => {
  const [hookLoading, setHookLoading] = useState(false);
  const { loading, error, data, refetch } = useQuery<T>(graphqlQuery, options);

  const tokenExpired = useMemo(() => {
    if (error && error.message === "[Auth] RequireAuth - Token invalid") {
      console.log("[Hook] useAuthQuery - Token expired");
      return true;
    } else if (error) {
      console.error("[Hook] useAuthQuery - Unknown error", error.message);
    }
    return false;
  }, [error]);

  useEffect(() => {
    const fetchNewToken = async () => {
      setHookLoading(true);
      const refreshToken = localStorage.getItem(STORAGE_KEY_AUTH_REFRESH_TOKEN);
      if (refreshToken) {
        await getNewToken(refreshToken);
        console.log("[Hook] useAuthQuery - Refetch Query");
        refetch();
      } else {
        console.log("[Hook] useAuthQuery - No refresh token available");
      }
      setHookLoading(false);
    };
    if (tokenExpired) {
      fetchNewToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenExpired]);

  return {
    loading: loading || hookLoading,
    error,
    data,
  };
};

export default useAuthQuery;
