import { useQuery as useGraphQlQuery } from "@apollo/client";
import { GET_CAR } from "../graphql/queries";
import { CarState } from "../types/cars";
import { useAppDispatch } from "../redux/hooks";
import { highlightCar } from "../redux/slices/shed/shedSlice";

type Props = {
  id: string;
};

type ReturnType = {
  loading: boolean;
};

type GetCarResponse = {
  getCarStorage: CarState;
};

const useGetCar = ({ id }: Props): ReturnType => {
  const dispatch = useAppDispatch();

  const { loading } = useGraphQlQuery<GetCarResponse>(GET_CAR, {
    onCompleted: ({ getCarStorage: car }) => dispatch(highlightCar(car)),
    variables: { id },
  });

  return {
    loading,
  };
};

export default useGetCar;
