import { useContext } from "react";
import { AlertContext } from "providers/AlertProvider";

type ReturnType = {
  displayMessage: (
    message: string,
    severity: "error" | "info" | "success" | "warning"
  ) => void;
};

const useDisplayMessage = (): ReturnType => {
  const { displayAlert } = useContext(AlertContext);

  return {
    displayMessage: displayAlert,
  };
};

export default useDisplayMessage;
