import { useQuery as useGraphQlQuery } from "@apollo/client";
import { GET_CAR_PAYMENTS } from "../graphql/queries";
import { useAppDispatch } from "../redux/hooks";
import { CarPaymentState } from "../types/cars";
import { highlightPayments } from "../redux/slices/shed/shedSlice";

type Props = {
  id: string;
};

type ReturnType = {
  loading: boolean;
};

type GetCarPaymentsResponse = {
  carPayments: Array<CarPaymentState>;
};

const useGetCarPayments = ({ id }: Props): ReturnType => {
  const dispatch = useAppDispatch();

  const { loading } = useGraphQlQuery<GetCarPaymentsResponse>(
    GET_CAR_PAYMENTS,
    {
      onCompleted: ({ carPayments }) =>
        dispatch(highlightPayments(carPayments)),
      variables: { carId: id },
    }
  );

  return {
    loading,
  };
};

export default useGetCarPayments;
