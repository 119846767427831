import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material";

import { GoogleMap } from "types/map";
import useMapRenderer from "hooks/maps/useMapRenderer";
import RainforestPopupInfo from "components/awareness/RainforestPopupInfo";
import CarNoEmissionPopup from "components/cars/CarNoEmissionPopup";

const APP_BOTTOM_BAR_HEIGHT = 56;
const MAP_CONTAINER_HEIGHT_BOUNDARIES = APP_BOTTOM_BAR_HEIGHT;

const MapBoundaries = styled("div")`
  height: calc(100vh - ${MAP_CONTAINER_HEIGHT_BOUNDARIES}px);
  width: 100%;
`;

interface Props extends google.maps.MapOptions {
  onClick?: (event: google.maps.MapMouseEvent) => void;
  onIdle?: (map: GoogleMap) => void;
  children: React.ReactElement[];
}

const MapContainer = ({
  children,
  onClick,
  onIdle,
  ...googleMapOptions
}: Props) => {
  const wrapperReference = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<GoogleMap>();
  const [infoOpen, setInfoOpen] = useState(true);

  useMapRenderer({
    onMapInitialized: setMap,
    mapWrapperReference: wrapperReference,
    googleMapOptions,
  });

  useEffect(() => {
    map && google.maps.event.clearListeners(map, "click");
    map && google.maps.event.clearListeners(map, "idle");
    map && onClick && map.addListener("click", onClick);
    map && onIdle && map.addListener("idle", () => onIdle(map));
  }, [map, onClick, onIdle]);

  return (
    <>
      {infoOpen && (
        <RainforestPopupInfo
          open={infoOpen}
          setOpen={(isOpen: boolean) => setInfoOpen(isOpen)}
        />
      )}

      <CarNoEmissionPopup />

      <MapBoundaries ref={wrapperReference} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map } as any);
        }
      })}
    </>
  );
};

export default MapContainer;
