import { severity } from "types/alert";

type UserMessage = {
  message: string;
  type: severity;
};

export const getAuthAlertMessage = (responseMessage: string): UserMessage => {
  const userMessage: UserMessage =
    responseMessage === "auth/wrong-password"
      ? { message: "Wrong Password", type: "error" }
      : responseMessage === "auth/user-not-found"
      ? { message: "User not found", type: "info" }
      : responseMessage === "auth/success"
      ? { message: "Success", type: "success" }
      : { message: "Unknown", type: "info" };

  return userMessage;
};
