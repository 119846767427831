import { useState } from "react";

import MapPanelSearch from "./MapPanelSearch";
import MapContainer from "./MapContainer";
import MapMarker from "./MapMarker";
import MapPOI from "./MapPOI";
import useDirections from "../../hooks/maps/useDirections";
import useNavLocation from "../../hooks/maps/useNavLocation";
import { LatLng } from "../../types/map";
import MapDrawer from "../map/MapDrawer";
import { useAppSelector } from "../../redux/hooks";
import { selectReferenceTrip } from "../../redux/slices/shed/shedSlice";
import MapPanelActions from "./MapPanelActions";
import useMapShareTripParams from "hooks/maps/useMapShareTripParams";

const MapPage = () => {
  useMapShareTripParams();

  const [centerGeo, setCenterGeo] = useState<LatLng>({ lat: 0, lng: 0 });
  const [zoomLevel, setZoomLevel] = useState(3);
  const [shedIcon] = useState<google.maps.Icon | undefined>(undefined);
  const {
    fromAddress,
    fromLatitude,
    fromLongitude,
    toAddress,
    toLatitude,
    toLongitude,
  } = useAppSelector(selectReferenceTrip);

  useDirections({
    from: {
      formattedAddress: fromAddress,
      latitude: fromLatitude,
      longitude: fromLongitude,
    },
    to: {
      formattedAddress: toAddress,
      latitude: toLatitude,
      longitude: toLongitude,
    },
  });

  useNavLocation({
    onInitCenterGeo: setCenterGeo,
    onInitZoomLevel: setZoomLevel,
  });

  return (
    <>
      <MapContainer
        center={
          fromLatitude && fromLongitude
            ? {
                lat: fromLatitude ?? 0,
                lng: fromLongitude ?? 0,
              }
            : centerGeo
        }
        zoom={zoomLevel}
        disableDefaultUI
        zoomControl
      >
        <MapMarker
          icon={shedIcon}
          position={
            fromLatitude && fromLongitude
              ? {
                  lat: fromLatitude ?? 0,
                  lng: fromLongitude ?? 0,
                }
              : centerGeo
          }
        />

        <MapMarker
          icon={shedIcon}
          position={{
            lat: toLatitude ?? 0,
            lng: toLongitude ?? 0,
          }}
        />

        <MapPOI lat={centerGeo.lat} lng={centerGeo.lng} />
      </MapContainer>
      <MapPanelSearch
        locationFrom={{
          formattedAddress: fromAddress,
          latitude: fromLatitude,
          longitude: fromLongitude,
        }}
        locationTo={{
          formattedAddress: toAddress,
          latitude: toLatitude,
          longitude: toLongitude,
        }}
      />
      <MapPanelActions />
      <MapDrawer />
    </>
  );
};

export default MapPage;
