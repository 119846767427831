import { Grid, Stack } from "@mui/material";
import { Commute } from "@mui/icons-material";
import AppTopBar from "../base-app/AppTopBar";
import { AppContainer } from "../base-app/App";
import { useSelector } from "react-redux";
import TripOverview from "./TripOverview";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import { TripState } from "../../types/user";
import TripOverviewSkeleton from "./TripOverviewSkeleton";
import {
  selectMyTrips,
  selectIsUserLoaded,
} from "../../redux/slices/shed/shedSlice";

const TripsDashboard = () => {
  const myTrips = useSelector(selectMyTrips);
  const isUserLoaded = useSelector(selectIsUserLoaded);

  const isAuthenticated = useIsAuthenticated();
  const loading = isAuthenticated && !isUserLoaded;

  return (
    <>
      <AppTopBar icon={<Commute color="primary" />} title={"Your Trips"} />
      <AppContainer>
        {loading ? (
          <Stack direction="row" alignItems="center" spacing={2}>
            <TripOverviewSkeleton />
            <TripOverviewSkeleton />
            <TripOverviewSkeleton />
          </Stack>
        ) : (
          <Grid container spacing={3}>
            {myTrips.map((trip: TripState, index: number) => (
              <Grid key={index} item xs={12} sm={6} lg={4}>
                <TripOverview trip={trip} />
              </Grid>
            ))}
          </Grid>
        )}
      </AppContainer>
    </>
  );
};

export default TripsDashboard;
