import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { STORAGE_KEY_AUTH_TOKEN } from "../components/base-app/LoginMagicCallback";

const useIsAuthenticated = (): boolean | null => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem(STORAGE_KEY_AUTH_TOKEN);
    setIsAuthenticated(Boolean(token));
  }, [location]);

  return isAuthenticated;
};

export default useIsAuthenticated;
