import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get, flow, find } from "lodash/fp";
import type { RootState } from "../../store";
import { tripEmission } from "../../../libraries/emission";
import { TripEmission, CarState, CarPaymentState } from "../../../types/cars";
import { MapItinerary } from "../../../types/map";
import { getNeutralTrip } from "libraries/trip";
import {
  MyUserState,
  PaymentState,
  TripLocation,
  TripState,
} from "../../../types/user";
import { omit } from "lodash";

/*
 * STORE TYPE
 */
type ShedState = {
  referenceItinerary: MapItinerary | null;
  referenceTrip: TripState;
  referenceCar: CarState | null;
  carHighlight: CarState | null;
  paymentsHighlight: Array<CarPaymentState> | null;
  // My user data
  userLoaded: boolean;
  userCars: CarState[];
  userPayments: PaymentState[];
  userTrips: TripState[];
  mapToggleValue: Boolean;
};

/*
 * STORE INITIAL STATE
 */
const initialState: ShedState = {
  referenceCar: null,
  referenceItinerary: null,
  referenceTrip: getNeutralTrip(),
  carHighlight: null,
  paymentsHighlight: null,
  userLoaded: false,
  userCars: [],
  userPayments: [],
  userTrips: [],
  mapToggleValue: false,
};

/*
 * STORE UPDATE QUERIES
 */
export const shedSlice = createSlice({
  name: "shed",
  initialState,
  reducers: {
    loadUserHomeData: (
      state,
      { payload: { cars, offsets, trips } }: PayloadAction<MyUserState>
    ) => {
      state.userLoaded = true;
      state.userCars = cars;
      state.userPayments = offsets;
      state.userTrips = trips;
    },
    addUserCar: (state, { payload: car }: PayloadAction<CarState>) => {
      state.userCars.push(car);
    },
    addUserTrip: (state, { payload: trip }: PayloadAction<TripState>) => {
      state.userTrips = [trip, ...state.userTrips];
    },

    removeTrip: (state, { payload: tripId }: PayloadAction<string>) => {
      state.userTrips = state.userTrips.filter((x) => x.tripId !== tripId);
    },

    clearItinerary: (state) => {
      state.referenceItinerary = null;
      state.referenceTrip = {
        ...getNeutralTrip(),
        carId: state.referenceTrip.carId,
        licensePlate: state.referenceTrip.licensePlate,
      };
    },

    clearTrip: (state) => {
      state.referenceTrip = getNeutralTrip();
    },
    registerReferenceTripFrequency: (state, action: PayloadAction<number>) => {
      state.referenceTrip.commuteFrequency = action.payload;
    },
    registerReferenceTripSelection: (
      state,
      action: PayloadAction<TripState>
    ) => {
      state.referenceTrip = action.payload;
    },
    registerReferenceItinerary: (
      state,
      action: PayloadAction<MapItinerary>
    ) => {
      state.referenceItinerary = action.payload;
    },

    updateMyUserTrips: (
      state,
      {
        payload: {
          tripId,
          fromLatitude,
          fromLongitude,
          toLatitude,
          toLongitude,
          fromAddress,
          toAddress,
          commuteFrequency,
        },
      }
    ) => {
      const trip = state.userTrips.find((trip) => trip.tripId === tripId);

      if (trip) {
        trip.fromAddress = fromAddress;
        trip.fromLatitude = fromLatitude;
        trip.fromLongitude = fromLongitude;
        trip.toAddress = toAddress;
        trip.toLatitude = toLatitude;
        trip.toLongitude = toLongitude;
        trip.commuteFrequency = commuteFrequency;
      }
    },
    registerReferenceTripLocation: (
      state,
      { payload: tripLocation }: PayloadAction<Partial<TripLocation> | null>
    ) => {
      const {
        fromLatitude,
        fromLongitude,
        toLatitude,
        toLongitude,
        fromAddress,
        toAddress,
      } = tripLocation || {};

      if (fromLatitude && fromLongitude) {
        state.referenceTrip.fromAddress = fromAddress ?? "";
        state.referenceTrip.fromLatitude = fromLatitude;
        state.referenceTrip.fromLongitude = fromLongitude;
      }

      if (toLatitude && toLongitude) {
        state.referenceTrip.toAddress = toAddress ?? "";
        state.referenceTrip.toLatitude = toLatitude;
        state.referenceTrip.toLongitude = toLongitude;
      }
    },
    registerReferenceCar: (state, action: PayloadAction<CarState>) => {
      state.referenceCar = action.payload;
    },
    highlightCar: (state, action: PayloadAction<CarState>) => {
      state.carHighlight = action.payload;
    },
    highlightPayments: (state, action: PayloadAction<CarPaymentState[]>) => {
      state.paymentsHighlight = action.payload;
    },

    registerMapToggleValue: (state, action: PayloadAction<Boolean>) => {
      state.mapToggleValue = action.payload;
    },
  },
});

/*
 * STORE SELECT QUERIES
 */

export const selectIsUserLoaded = (state: RootState): boolean =>
  state.shed.userLoaded;

export const selectMyCars = (state: RootState): CarState[] =>
  state.shed.userCars;

export const selectMyPayments = (state: RootState): PaymentState[] =>
  state.shed.userPayments;

export const selectMyTrips = (state: RootState): TripState[] =>
  state.shed.userTrips;

export const selectHasReferenceTrip = (state: RootState): boolean =>
  Boolean(state.shed.referenceItinerary && state.shed.referenceCar);

export const selectToggleDrawer = (state: RootState): Boolean =>
  state.shed.mapToggleValue;

export const selectReferenceTrip = (state: RootState): TripState =>
  state.shed.referenceTrip;

export const selectReferenceCar = (state: RootState): CarState | null =>
  state.shed.referenceCar;

export const selectReferenceItinerary = (
  state: RootState
): MapItinerary | null => state.shed.referenceItinerary;

export const selectReferenceTripEmission = (state: RootState): TripEmission =>
  tripEmission(
    state.shed.referenceCar,
    state.shed.referenceItinerary?.distanceMeters ?? 0
  );

export const selectCarHighlight = (state: RootState): CarState | null =>
  state.shed.carHighlight;

export const selectPaymentsHighlight = (
  state: RootState
): Array<CarPaymentState> | null => state.shed.paymentsHighlight;

export const selectCarByLicensePlate = (licensePlate: string) =>
  flow(
    get("shed.userCars"),
    find({ licensePlate: licensePlate }),
    (car: CarState | undefined) => omit(car, ["__typename"]) ?? null // Type inference
  );

export const selectCarById = (carId: string) =>
  flow(
    get("shed.userCars"),
    find({ id: carId }),
    (car: CarState | undefined) => omit(car, ["__typename"]) ?? null // Type inference
  );

/*
 * STORE EXPORT DEFAULT
 */
export const {
  registerReferenceItinerary,
  clearItinerary,
  clearTrip,
  loadUserHomeData,
  addUserCar,
  addUserTrip,
  removeTrip,
  registerReferenceCar,
  registerReferenceTripSelection,
  registerReferenceTripLocation,
  registerReferenceTripFrequency,
  registerMapToggleValue,
  updateMyUserTrips,
  highlightCar,
  highlightPayments,
} = shedSlice.actions;
export default shedSlice.reducer;
