import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Backdrop, CircularProgress, Typography } from "@mui/material";

import AppTopBar from "../base-app/AppTopBar";
import { AppContainer } from "../base-app/App";
import useGetCar from "../../hooks/useGetCar";
import useGetCarPayments from "../../hooks/useGetCarPayments";
import { useAppSelector } from "../../redux/hooks";
import { selectCarHighlight } from "../../redux/slices/shed/shedSlice";
import { getNeutralCar } from "../../libraries/car";
import { useLazyQuery } from "@apollo/client";
import { GET_CAR_TRIPS } from "../../graphql/queries";
import CarDetailsHeader from "./CarDetailsHeader";

const CarPage = () => {
  const { id } = useParams();
  const { loading: carLoading } = useGetCar({ id: id ?? "" });

  const [myCarTrips] = useLazyQuery(GET_CAR_TRIPS);

  useEffect(() => {
    myCarTrips({ variables: { carId: id } });
  }, [myCarTrips, id]);

  useGetCarPayments({ id: id ?? "" });

  const carHighlight = useAppSelector(selectCarHighlight);
  const { licensePlate, carBrand, carModel, nextControl } =
    carHighlight ?? getNeutralCar();

  return (
    <>
      <AppTopBar title={carBrand + " " + carModel} carBrand={carBrand} />
      <AppContainer>
        <Backdrop
          open={carLoading}
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress />
        </Backdrop>

        <CarDetailsHeader
          licensePlate={licensePlate}
          carBrand={carBrand}
          carModel={carModel}
          nextControl={nextControl}
        />

        <Typography variant="h6" mb={2} mt={5} top={15}>
          Stay tuned: More data about your car will come here
        </Typography>
      </AppContainer>
    </>
  );
};

export default CarPage;
