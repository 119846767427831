import { CarGenericState, CarState } from "types/cars";

export const getNeutralCar = (): CarState => ({
  id: "",
  licensePlate: "",
  genericVin: "",
  emissionCO2: 0,
  emissionType: "",
  carBrand: "",
  nextControl: "",
  previousControl: "",
  carModel: "",
});

export const carToCar = (car: CarState): CarState => ({
  id: car.id,
  genericVin: car.genericVin,
  licensePlate: car.licensePlate,
  carBrand: car.carBrand,
  carModel: car.carModel,
  emissionCO2: car.emissionCO2,
  emissionType: car.emissionType,
  nextControl: car.nextControl,
  previousControl: car.previousControl,
});

export const genericCarToCar = (genericCar: CarGenericState): CarState => ({
  id: "",
  licensePlate: "",
  genericVin: genericCar.genericVin,
  carBrand: genericCar.carBrand,
  carModel: genericCar.carModel,
  emissionCO2: genericCar.emissionCO2,
  emissionType: genericCar.emissionType,
  nextControl: "",
  previousControl: "",
});
