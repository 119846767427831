import { useCallback } from "react";
import { SelectChangeEvent } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useAppSelector } from "redux/hooks";
import { selectReferenceTrip } from "redux/slices/shed/shedSlice";
import { tripCO2ToCostCalculation } from "libraries/donation";

type Props = {
  donationSuggestion: number;
  setSelectedAmount: (amount: number) => void;
  setNumberOfTripsToDonate: (numberOfTrips: number) => void;
  selectedAmount: number | null;
  numberOfTripsToDonate: number;
  totalKgEmission: number;
};

const CarDonationSelect = ({
  donationSuggestion,
  setSelectedAmount,
  setNumberOfTripsToDonate,
  numberOfTripsToDonate,
  totalKgEmission,
}: Props) => {
  const referenceTrip = useAppSelector(selectReferenceTrip);

  const tripDonationSugestion = () => {
    return parseFloat(donationSuggestion.toFixed(2));
  };

  const handleDonationChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      const donationAmount = tripCO2ToCostCalculation(
        totalKgEmission,
        Number(event.target.value)
      );

      setSelectedAmount(donationAmount);

      setNumberOfTripsToDonate(Number(event.target.value));
    },
    [setSelectedAmount, totalKgEmission, setNumberOfTripsToDonate]
  );

  return (
    <FormControl sx={{ display: "flex", justifyContent: "center" }}>
      <InputLabel id="donation-input">Donate</InputLabel>
      <Select
        labelId="donation-simple-select-label"
        id="donation-simple-select-label"
        label="Donate"
        value={numberOfTripsToDonate}
        onChange={handleDonationChange}
      >
        <MenuItem value={referenceTrip.commuteFrequency}>
          {tripDonationSugestion()} kr (All {referenceTrip.commuteFrequency}{" "}
          trips ){(totalKgEmission * referenceTrip.commuteFrequency).toFixed(1)}{" "}
          KG CO2
        </MenuItem>

        <MenuItem value={1}>
          {tripCO2ToCostCalculation(totalKgEmission, 1)}kr (1 trip){" "}
          {(totalKgEmission * 1).toFixed(1)} kg CO2
        </MenuItem>
        <MenuItem value={2}>
          {tripCO2ToCostCalculation(totalKgEmission, 2)} kr (2 trips){" "}
          {(totalKgEmission * 2).toFixed(1)} kg CO2
        </MenuItem>
        <MenuItem value={3}>
          {tripCO2ToCostCalculation(totalKgEmission, 3)} kr (3 trips ){" "}
          {(totalKgEmission * 3).toFixed(1)} kg CO2
        </MenuItem>
        <MenuItem value={5}>
          {tripCO2ToCostCalculation(totalKgEmission, 5)} kr(5 trips){" "}
          {(totalKgEmission * 5).toFixed(1)} kg CO2
        </MenuItem>
        <MenuItem value={10}>
          {tripCO2ToCostCalculation(totalKgEmission, 10)} kr(10 trips){" "}
          {(totalKgEmission * 10).toFixed(1)} kg CO2
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default CarDonationSelect;
