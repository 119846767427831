import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./index.css";
import AppRoutes from "./components/base-app/AppRoutes";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import GraphqlProvider from "providers/Graphql";
import MapProvider from "providers/MapProvider";
import AlertProvider from "providers/AlertProvider";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://59260aca13e04899ac619d28786dfd74@o1114033.ingest.sentry.io/6555954",
    integrations: [
      new BrowserTracing(),
      new CaptureConsole({
        levels: ["warn", "error"],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        light: "#3b4343",
        main: "#0B1414",
        dark: "#0B1414",
        contrastText: "#FCFCFC",
      },
      secondary: {
        light: "#ffcd9b",
        main: "#f79c6c",
        dark: "#c16d40",
        contrastText: "#000000",
      },
    },
  })
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ReduxProvider store={store}>
    <GraphqlProvider>
      <AlertProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MapProvider>
            <AppRoutes />
          </MapProvider>
        </ThemeProvider>
      </AlertProvider>
    </GraphqlProvider>
  </ReduxProvider>
);

// Service worker
const onUpdate = (registration: any) => {
  if (registration && registration.waiting) {
    registration.waiting.postMessage({ type: "SKIP_WAITING" });
    registration.waiting.addEventListener("statechange", (e: any) => {
      if (e.target.state === "activated") {
        window.location.reload();
      }
    });
  }
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: onUpdate,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
