export const DEFAULT_DONATION_AMOUNT = 100;
export const UNSET_DONATION_SUGGESTION = -1;

const RAINFOREST_TREES_PER_FINANCIAL_UNIT = 5;

export const tripCO2ToCostCalculation = (
  tripCO2: number,
  commuteFrequency: number
): number => {
  const COST_PER_TREE_RAINFOREST_KRONER = 5;
  const YEARLY_TREE_ABSORPTION = 25;

  const RAINFOREST_TREES_NEEDED_TO_ABSORB_CO2 =
    (tripCO2 * commuteFrequency) / YEARLY_TREE_ABSORPTION;

  const RAINFOREST_COST_TRIP =
    RAINFOREST_TREES_NEEDED_TO_ABSORB_CO2 * COST_PER_TREE_RAINFOREST_KRONER;

  const SOCIAL_COST_OF_TRIP = tripCO2 * commuteFrequency * 1.25;

  const TOTAL_TRIP_COST = (RAINFOREST_COST_TRIP + SOCIAL_COST_OF_TRIP).toFixed(
    2
  );

  return parseFloat(TOTAL_TRIP_COST);
};

export const donationAmountToTrees = (donation: number): number =>
  Number((donation / RAINFOREST_TREES_PER_FINANCIAL_UNIT).toFixed(2));
