import React from "react";
import { useEffect, useState } from "react";
import { includes } from "lodash/fp";
import { useLocation } from "react-router";
import useAppNavigate from "../useAppNavigate";
import { ROUTE } from "../../components/base-app/AppRoutes";

export enum NAVIGATION_ITEM {
  MAP = "map",
  TRIPS = "trips",
  CARS = "cars",
  PROFILE = "profile",
  LOGIN = "login",
}

type ReturnProps = {
  bottomItem: NAVIGATION_ITEM;
  handleNavigationChange: (
    _: React.SyntheticEvent,
    value: NAVIGATION_ITEM
  ) => void;
};

const useBottomBar = (): ReturnProps => {
  const [bottomItem, setBottomItem] = useState<NAVIGATION_ITEM>(
    NAVIGATION_ITEM.MAP
  );

  const navigateTo = useAppNavigate();

  const location = useLocation();

  useEffect(() => {
    if (includes("car")(location.pathname)) {
      setBottomItem(NAVIGATION_ITEM.CARS);
    } else if (includes("map")(location.pathname)) {
      setBottomItem(NAVIGATION_ITEM.MAP);
    } else if (includes("profile")(location.pathname)) {
      setBottomItem(NAVIGATION_ITEM.PROFILE);
    } else if (includes("trip")(location.pathname)) {
      setBottomItem(NAVIGATION_ITEM.TRIPS);
    } else if (includes("login")(location.pathname)) {
      setBottomItem(NAVIGATION_ITEM.LOGIN);
    }
  }, [location]);

  const handleNavigationChange = (
    _: React.SyntheticEvent,
    value: NAVIGATION_ITEM
  ) => {
    switch (value) {
      case NAVIGATION_ITEM.CARS:
        navigateTo(ROUTE.CARS_PAGE);
        break;
      case NAVIGATION_ITEM.MAP:
        navigateTo(ROUTE.MAP);
        break;
      case NAVIGATION_ITEM.PROFILE:
        navigateTo(ROUTE.PROFILE_PAGE);
        break;
      case NAVIGATION_ITEM.TRIPS:
        navigateTo(ROUTE.TRIPS_DASHBOARD);
        break;
      case NAVIGATION_ITEM.LOGIN:
        navigateTo(ROUTE.LOG_IN_EMAIL_AND_PASSWORD);
        break;

      default:
        console.warn("[Hook] useBottomBar - Navigation value not implemented");
        break;
    }
    setBottomItem(value);
  };

  return {
    bottomItem,
    handleNavigationChange,
  };
};

export default useBottomBar;
