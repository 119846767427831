type Props = {
  carBrand: string;
  size?: "small" | "large" | "full";
};

const LOGO_SIZE_LIMIT = 75;
const LOGO_SIZE_LARGE = 45;
const LOGO_SIZE_SMALL = 20;

export const sizeToPixel = (size?: "small" | "large"): number =>
  size === "large"
    ? LOGO_SIZE_LARGE
    : size === "small"
    ? LOGO_SIZE_SMALL
    : LOGO_SIZE_SMALL;

const CarLogoBrand = ({ carBrand, size }: Props) => (
  <img
    alt="brand-logo"
    style={{
      width: size === "full" ? undefined : sizeToPixel(size),
      height: size === "full" ? undefined : sizeToPixel(size),
      maxWidth: LOGO_SIZE_LIMIT,
      maxHeight: LOGO_SIZE_LIMIT,
    }}
    src={process.env.PUBLIC_URL + `/brands/${carBrand}.svg`}
    onError={({ currentTarget }) => {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src = process.env.PUBLIC_URL + `/brands/car.svg`;
    }}
  />
);

export default CarLogoBrand;
