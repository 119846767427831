import { Box } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppTopBar from "../base-app/AppTopBar";
import ProfileDashboard from "../payment/ProfileDashboard";
import { AppContainer } from "../base-app/App";
import LogOutButton from "../login/LogOutButton";
import ProfileTerms from "./ProfileTerms";

const TOP_BAR_HEIGHT = 64;

const ProfilePage = () => (
  <>
    <AppTopBar
      icon={<AccountCircleIcon color="primary" />}
      title={"Your Forest"}
    />
    <AppContainer
      sx={{
        minHeight: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ProfileDashboard />
      <Box flexGrow={1} />
      <ProfileTerms />
      <Box margin="auto" mt={1}>
        <LogOutButton />
      </Box>
    </AppContainer>
  </>
);
export default ProfilePage;
