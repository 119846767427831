import { Box, Card, CardActions } from "@mui/material";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { TripState } from "types/user";
import { selectCarById } from "redux/slices/shed/shedSlice";
import TripCard from "components/trips/TripCard";
import TripDonate from "components/trips/TripDonate";
import TripMapNavigate from "components/trips/TripMapNavigate";
import ShareTripButton from "components/cars/ShareTripButton";

type Props = {
  trip: TripState;
};

const TripOverview = ({ trip }: Props) => {
  const { carId } = trip;
  const tripCar = useSelector(selectCarById(carId));

  return (
    <Card>
      <TripCard trip={trip} tripCar={tripCar} />

      <CardActions
        sx={{
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <Box display="flex" width="100%">
          <TripMapNavigate trip={trip} />
          <ShareTripButton trip={trip} genericVin={tripCar.genericVin} />
          <Box flexGrow={1} />
          <TripDonate trip={trip} tripCar={tripCar} />
        </Box>
      </CardActions>
    </Card>
  );
};

export default TripOverview;
