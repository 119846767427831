import React from "react";
import { styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ReactComponent as VippsLogo } from "./vipps_logo.svg";

type Props = {
  onClick: () => void;
  loading: boolean;
};

const VIPPS_COLOR_THEME = "#FF5b24";

const StyledButton = styled(LoadingButton)(
  ({ theme }) => `
  background-color: ${VIPPS_COLOR_THEME};
  color: ${theme.palette.common.white};
  min-height: 60px;
  padding-left: ${theme.spacing(3)};
  padding-right: ${theme.spacing(3)};
  font-weight: bold;
  &:hover {
    background-color: ${VIPPS_COLOR_THEME};
  }
  &:active {
    background-color: ${VIPPS_COLOR_THEME}
  }
 `
);

const VippsButton = ({ onClick, loading }: Props) => (
  <StyledButton onClick={onClick} loading={loading}>
    <VippsLogo />
  </StyledButton>
);

export default VippsButton;
