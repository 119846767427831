import { Container, Typography } from "@mui/material";

const PaymentTermsPage = () => {
  return (
    <Container maxWidth="md" style={{ padding: "2rem", marginBottom: "100px" }}>
      <Typography variant="h4" gutterBottom>
        Salgsvilkår donasjoner gjennom Shed AS
      </Typography>

      <Typography variant="body1" paragraph>
        Donasjoner gjort gjennom Shed-appen går direkte til Regnskogfondet uten
        mellomledd. Nettopp derfor vil Regnskogfondet komme opp direkte når du
        betaler gjennom vipps. Ved spørsmål kan du ta kontakt med
        joachim.sogn@shed.no
      </Typography>

      <Typography variant="h5" gutterBottom>
        1. Avtalen
      </Typography>
      <Typography variant="body1" paragraph>
        Avtalen består av disse salgsbetingelsene, opplysninger gitt i
        bestillingsløsningen og eventuelt særskilt avtalte vilkår. Ved eventuell
        motstrid mellom opplysningene, går det som særskilt er avtalt mellom
        partene foran, så fremt det ikke strider mot ufravikelig lovgivning.
      </Typography>
      <Typography variant="body1" paragraph>
        Avtalen vil i tillegg bli utfylt av relevante lovbestemmelser som
        regulerer kjøp av varer mellom næringsdrivende og forbrukere.
      </Typography>

      <Typography variant="h5" gutterBottom>
        2. Partene
      </Typography>
      <Typography variant="body1" paragraph>
        Selger er Regnskogfondet, Marboes gate 8, regnskog@regnskog.no,
        23109500, 985 828 806, og betegnes i det følgende som selger/selgeren.
      </Typography>
      <Typography variant="body1" paragraph>
        Kjøper er den forbrukeren som foretar bestillingen, og betegnes i det
        følgende som kjøper/kjøperen.
      </Typography>
      <Typography variant="body1" paragraph>
        Donasjoner går direkte til regnskogfondets konto.
      </Typography>

      <Typography variant="h5" gutterBottom>
        3. Pris
      </Typography>
      <Typography variant="body1" paragraph>
        Den oppgitte prisen for varen og tjenester er den totale prisen kjøper
        skal betale. Denne prisen inkluderer alle avgifter og tilleggskostnader.
        Ytterligere kostnader som selger før kjøpet ikke har informert om, skal
        kjøper ikke bære.
      </Typography>

      <Typography variant="h5" gutterBottom>
        4. Avtaleinngåelse
      </Typography>
      <Typography variant="body1" paragraph>
        Avtalen er bindende for begge parter når kjøperen har sendt sin
        bestilling til selgeren.
      </Typography>
      <Typography variant="body1" paragraph>
        Avtalen er likevel ikke bindende hvis det har forekommet skrive- eller
        tastefeil i tilbudet fra selgeren i bestillingsløsningen i nettbutikken
        eller i kjøperens bestilling, og den annen part innså eller burde ha
        innsett at det forelå en slik feil.
      </Typography>

      <Typography variant="h5" gutterBottom>
        5. Betalingen
      </Typography>
      <Typography variant="body1" paragraph>
        Selgeren kan kreve betaling for varen fra det tidspunkt den blir sendt
        fra selgeren til kjøperen.
      </Typography>
      <Typography variant="body1" paragraph>
        Dersom kjøperen bruker kredittkort eller debetkort ved betaling, kan
        selgeren reservere kjøpesummen på kortet ved bestilling. Kortet blir
        belastet samme dag som varen sendes.
      </Typography>
      <Typography variant="body1" paragraph>
        Ved betaling med faktura, blir fakturaen til kjøperen utstedt ved
        forsendelse av varen. Betalingsfristen fremgår av fakturaen og er på
        minimum 14 dager fra mottak.
      </Typography>
      <Typography variant="body1" paragraph>
        Kjøpere under 18 år kan ikke betale med etterfølgende faktura.
      </Typography>
      <Typography variant="body1" paragraph>
        Donasjoner kan ikke betales tilbake fra regnskogfondet.
      </Typography>

      <Typography variant="h5" gutterBottom>
        6. Levering
      </Typography>
      <Typography variant="body1" paragraph>
        Levering er skjedd når kjøperen, eller hans representant, har overtatt
        tingen.
      </Typography>
      <Typography variant="body1" paragraph>
        Hvis ikke leveringstidspunkt fremgår av bestillingsløsningen, skal
        selgeren levere varen til kjøper uten unødig opphold og senest 30 dager
        etter bestillingen fra kunden. Varen skal leveres hos kjøperen med
        mindre annet er særskilt avtalt mellom partene.
      </Typography>
      <Typography variant="body1" paragraph>
        Det blir ikke levert noen fysiske varer i dette tilfellet fordi alt som
        skjer gjennom appen er å gjøre donasjoner direkte til Regnskogfondet.
      </Typography>

      <Typography variant="h5" gutterBottom>
        7. Risikoen for varen
      </Typography>
      <Typography variant="body1" paragraph>
        Risikoen for varen går over på kjøper når han, eller kjøpers
        representant, har fått varene levert i tråd med punkt 6.
      </Typography>

      <Typography variant="h5" gutterBottom>
        8. Angrerett
      </Typography>
      <Typography variant="body1" paragraph>
        Med mindre avtalen er unntatt fra angrerett, kan kjøperen angre kjøpet
        av varen i henhold til angrerettloven.
      </Typography>
      <Typography variant="body1" paragraph>
        Kjøperen må gi selger melding om bruk av angreretten innen 14 dager fra
        fristen begynner å løpe. I fristen inkluderes alle kalenderdager. Dersom
        fristen ender på en lørdag, helligdag eller høytidsdag forlenges fristen
        til nærmeste virkedag.
      </Typography>
      <Typography variant="body1" paragraph>
        Angrefristen anses overholdt dersom melding er sendt før utløpet av
        fristen. Kjøper har bevisbyrden for at angreretten er blitt gjort
        gjeldende, og meldingen bør derfor skje skriftlig (angrerettskjema,
        e-post eller brev).
      </Typography>
      <Typography variant="body1" paragraph>
        Angrefristen begynner å løpe:
        <ul>
          <li>
            Ved kjøp av enkeltstående varer vil angrefristen løpe fra dagen
            etter varen(e) er mottatt.
          </li>
          <li>
            Selges et abonnement, eller innebærer avtalen regelmessig levering
            av identiske varer, løper fristen fra dagen etter første forsendelse
            er mottatt.
          </li>
          <li>
            Består kjøpet av flere leveranser, vil angrefristen løpe fra dagen
            etter siste leveranse er mottatt.
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" paragraph>
        Angrefristen utvides til 12 måneder etter utløpet av den opprinnelige
        fristen dersom selger ikke før avtaleinngåelsen opplyser om at det
        foreligger angrerett og standardisert angreskjema. Tilsvarende gjelder
        ved manglende opplysning om vilkår, tidsfrister og fremgangsmåte for å
        benytte angreretten. Sørger den næringsdrivende for å gi opplysningene i
        løpet av disse 12 månedene, utløper angrefristen likevel 14 dager etter
        den dagen kjøperen mottok opplysningene.
      </Typography>
      <Typography variant="body1" paragraph>
        Ved bruk av angreretten må varen leveres tilbake til selgeren uten
        unødig opphold og senest 14 dager fra melding om bruk av angreretten er
        gitt. Kjøper dekker de direkte kostnadene ved å returnere varen, med
        mindre annet er avtalt eller selger har unnlatt å opplyse om at kjøper
        skal dekke returkostnadene. Selgeren kan ikke fastsette gebyr for
        kjøperens bruk av angreretten.
      </Typography>
      <Typography variant="body1" paragraph>
        Kjøper kan prøve eller teste varen på en forsvarlig måte for å fastslå
        varens art, egenskaper og funksjon, uten at angreretten faller bort.
        Dersom prøving eller test av varen går utover hva som er forsvarlig og
        nødvendig, kan kjøperen bli ansvarlig for eventuell redusert verdi på
        varen.
      </Typography>
      <Typography variant="body1" paragraph>
        Selgeren er forpliktet til å tilbakebetale kjøpesummen til kjøperen uten
        unødig opphold, og senest 14 dager fra selgeren fikk melding om
        kjøperens beslutning om å benytte angreretten. Selger har rett til å
        holde tilbake betalingen til han/hun har mottatt varene fra kjøperen,
        eller til kjøper har lagt frem dokumentasjon for at varene er sendt
        tilbake.
      </Typography>

      <Typography variant="h5" gutterBottom>
        9. Forsinkelse og manglende levering - kjøpernes rettigheter og frist
        for å melde krav
      </Typography>
      <Typography variant="body1" paragraph>
        Dersom selgeren ikke leverer varen eller leverer den for sent i henhold
        til avtalen mellom partene, og dette ikke skyldes kjøperen eller forhold
        på kjøperens side, kan kjøperen i henhold til reglene i
        forbrukerkjøpslovens kapittel 5 etter omstendighetene holde kjøpesummen
        tilbake, kreve oppfyllelse, heve avtalen og/eller kreve erstatning fra
        selgeren.
      </Typography>
      <Typography variant="body1" paragraph>
        Ved krav om misligholdsbeføyelser bør meldingen av bevishensyn være
        skriftlig (for eksempel e-post).
      </Typography>
      <Typography variant="body1" paragraph>
        Oppfyllelse Kjøper kan fastholde kjøpet og kreve oppfyllelse fra selger.
        Kjøper kan imidlertid ikke kreve oppfyllelse dersom det foreligger en
        hindring som selgeren ikke kan overvinne, eller dersom oppfyllelse vil
        medføre en så stor ulempe eller kostnad for selger at det står i
        vesentlig misforhold til kjøperens interesse i at selgeren oppfyller.
        Skulle vanskene falle bort innen rimelig tid, kan kjøper likevel kreve
        oppfyllelse.
      </Typography>
      <Typography variant="body1" paragraph>
        Kjøperen taper sin rett til å kreve oppfyllelse om han eller hun venter
        urimelig lenge med å fremme kravet.
      </Typography>
      <Typography variant="body1" paragraph>
        Heving Dersom selgeren ikke leverer varen på leveringstidspunktet, skal
        kjøperen oppfordre selger til å levere innen en rimelig tilleggsfrist
        for oppfyllelse. Dersom selger ikke leverer varen innen tilleggsfristen,
        kan kjøperen heve kjøpet.
      </Typography>
      <Typography variant="body1" paragraph>
        Kjøper kan imidlertid heve kjøpet umiddelbart hvis selger nekter å
        levere varen. Tilsvarende gjelder dersom levering til avtalt tid var
        avgjørende for inngåelsen av avtalen, eller dersom kjøperen har
        underrettet selger om at leveringstidspunktet er avgjørende.
      </Typography>
      <Typography variant="body1" paragraph>
        Leveres tingen etter tilleggsfristen forbrukeren har satt eller etter
        leveringstidspunktet som var avgjørende for inngåelsen av avtalen, må
        krav om heving gjøres gjeldende innen rimelig tid etter at kjøperen fikk
        vite om leveringen.
      </Typography>
      <Typography variant="body1" paragraph>
        Erstatning Kjøperen kan kreve erstatning for lidt tap som følge av
        forsinkelsen. Dette gjelder imidlertid ikke dersom selgeren godtgjør at
        forsinkelsen skyldes hindring utenfor selgers kontroll som ikke med
        rimelighet kunne blitt tatt i betraktning på avtaletiden, unngått, eller
        overvunnet følgene av.
      </Typography>

      <Typography variant="h5" gutterBottom>
        10. Mangel ved varen - kjøperens rettigheter og reklamasjonsfrist
      </Typography>
      <Typography variant="body1" paragraph>
        Hvis det foreligger en mangel ved varen må kjøper innen rimelig tid
        etter at den ble oppdaget eller burde ha blitt oppdaget, gi selger
        melding om at han eller hun vil påberope seg mangelen. Kjøper har alltid
        reklamert tidsnok dersom det skjer innen 2 mnd. fra mangelen ble
        oppdaget eller burde blitt oppdaget. Reklamasjon kan skje senest to år
        etter at kjøper overtok varen. Dersom varen eller deler av den er ment å
        vare vesentlig lenger enn to år, er reklamasjonsfristen fem år.
      </Typography>
      <Typography variant="body1" paragraph>
        Dersom varen har en mangel og dette ikke skyldes kjøperen eller forhold
        på kjøperens side, kan kjøperen i henhold til reglene i
        forbrukerkjøpsloven kapittel 6 etter omstendighetene holde kjøpesummen
        tilbake, velge mellom retting og omlevering, kreve prisavslag, kreve
        avtalen hevet og/eller kreve erstatning fra selgeren.
      </Typography>
      <Typography variant="body1" paragraph>
        Reklamasjon til selgeren bør skje skriftlig.
      </Typography>
      <Typography variant="body1" paragraph>
        Retting eller omlevering Kjøperen kan velge mellom å kreve mangelen
        rettet eller levering av tilsvarende ting. Selger kan likevel motsette
        seg kjøperens krav dersom gjennomføringen av kravet er umulig eller
        volder selgeren urimelige kostnader. Retting eller omlevering skal
        foretas innen rimelig tid. Selger har i utgangspunktet ikke rett til å
        foreta mer enn to avhjelpsforsøk for samme mangel.
      </Typography>
      <Typography variant="body1" paragraph>
        Prisavslag Kjøper kan kreve et passende prisavslag dersom varen ikke
        blir rettet eller omlevert. Dette innebærer at forholdet mellom nedsatt
        og avtalt pris svarer til forholdet mellom tingens verdi i mangelfull og
        kontraktsmessig stand. Dersom særlige grunner taler for det, kan
        prisavslaget i stedet settes lik mangelens betydning for kjøperen.
      </Typography>
      <Typography variant="body1" paragraph>
        Heving Dersom varen ikke er rettet eller omlevert, kan kjøperen også
        heve kjøpet når mangelen ikke er uvesentlig.
      </Typography>

      <Typography variant="h5" gutterBottom>
        11. Selgerens rettigheter ved kjøperens mislighold
      </Typography>
      <Typography variant="body1" paragraph>
        Dersom kjøperen ikke betaler eller oppfyller de øvrige pliktene etter
        avtalen eller loven, og dette ikke skyldes selgeren eller forhold på
        selgerens side, kan selgeren i henhold til reglene i forbrukerkjøpsloven
        kapittel 9 etter omstendighetene holde varen tilbake, kreve oppfyllelse
        av avtalen, kreve avtalen hevet samt kreve erstatning fra kjøperen.
        Selgeren vil også etter omstendighetene kunne kreve renter ved forsinket
        betaling, inkassogebyr og et rimelig gebyr ved uavhentede varer.
      </Typography>
      <Typography variant="body1" paragraph>
        Oppfyllelse Selger kan fastholde kjøpet og kreve at kjøperen betaler
        kjøpesummen. Er varen ikke levert, taper selgeren sin rett dersom han
        venter urimelig lenge med å fremme kravet.
      </Typography>
      <Typography variant="body1" paragraph>
        Heving Selger kan heve avtalen dersom det foreligger vesentlig
        betalingsmislighold eller annet vesentlig mislighold fra kjøperens side.
        Selger kan likevel ikke heve dersom hele kjøpesummen er betalt.
        Fastsetter selger en rimelig tilleggsfrist for oppfyllelse og kjøperen
        ikke betaler innen denne fristen, kan selger heve kjøpet.
      </Typography>
      <Typography variant="body1" paragraph>
        Renter ved forsinket betaling/inkassogebyr Dersom kjøperen ikke betaler
        kjøpesummen i henhold til avtalen, kan selger kreve renter av
        kjøpesummen etter forsinkelsesrenteloven. Ved manglende betaling kan
        kravet, etter forutgående varsel, bli sendt til inkasso. Kjøper kan da
        bli holdt ansvarlig for gebyr etter inkassoloven.
      </Typography>
      <Typography variant="body1" paragraph>
        Gebyr ved uavhentede ikke-forskuddsbetalte varer Dersom kjøperen
        unnlater å hente ubetalte varer, kan selger belaste kjøper med et gebyr.
        Gebyret skal maksimalt dekke selgerens faktiske utlegg for å levere
        varen til kjøperen. Et slikt gebyr kan ikke belastes kjøpere under 18
        år.
      </Typography>

      <Typography variant="h5" gutterBottom>
        12. Garanti
      </Typography>
      <Typography variant="body1" paragraph>
        Garanti som gis av selgeren eller produsenten, gir kjøperen rettigheter
        i tillegg til de kjøperen allerede har etter ufravikelig lovgivning. En
        garanti innebærer dermed ingen begrensninger i kjøperens rett til
        reklamasjon og krav ved forsinkelse eller mangler etter punkt 9 og 10.
      </Typography>

      <Typography variant="h5" gutterBottom>
        13. Personopplysninger
      </Typography>
      <Typography variant="body1" paragraph>
        Behandlingsansvarlig for innsamlede personopplysninger er selger. Med
        mindre kjøperen samtykker til noe annet, kan selgeren, i tråd med
        personopplysningsloven, kun innhente og lagre de personopplysninger som
        er nødvendig for at selgeren skal kunne gjennomføre forpliktelsene etter
        avtalen. Kjøperens personopplysninger vil kun bli utlevert til andre
        hvis det er nødvendig for at selger skal få gjennomført avtalen med
        kjøperen, eller i lovbestemte tilfelle.
      </Typography>

      <Typography variant="h5" gutterBottom>
        14. Konfliktløsning
      </Typography>
      <Typography variant="body1" paragraph>
        Klager rettes til selger innen rimelig tid, jf. punkt 9 og 10. Partene
        skal forsøke å løse eventuelle tvister i minnelighet. Dersom dette ikke
        lykkes, kan kjøperen ta kontakt med Forbrukertilsynet for mekling.
        Forbrukertilsynet er tilgjengelig på telefon 23 400 600 eller
        www.forbrukertilsynet.no.
      </Typography>
      <Typography variant="body1" paragraph>
        Europa-Kommisjonens klageportal kan også brukes hvis du ønsker å inngi
        en klage. Det er særlig relevant, hvis du er forbruker bosatt i et annet
        EU-land. Klagen inngis her:{" "}
        <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>.
      </Typography>
    </Container>
  );
};

export default PaymentTermsPage;
