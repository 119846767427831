import { Outlet, BrowserRouter, Routes, Route } from "react-router-dom";

import App from "./App";
import CarsPage from "../cars/CarsPage";
import CarPage from "../cars/CarPage";
import LoginMagicCallback from "./LoginMagicCallback";
import MapPage from "../map/MapPage";
import ProfilePage from "../profile/ProfilePage";
import ThankYouPage from "../payment/ThankYouPage";
import PaymentFallbackPage from "../payment/PaymentFallbackPage";
import LoginMagicLinkPage from "./LoginMagicLinkPage";
import LoginEmailAndPasswordPage from "../login/LoginEmailAndPasswordPage";
import AppAuthenticationPipe from "./AppAuthenticationPipe";
import AppHomeLoaderPipe from "./AppHomeLoaderPipe";
import TripsDashboard from "../trips/TripsDashboard";
import PaymentTermsPage from "../payment/PaymentTermsPage";
import ResetPassword from "components/login/ResetPassword";
import ForgetPasswordPage from "components/login/ForgetPaswordPage";
import CreateNewUserPage from "components/login/CreateNewUserPage";

enum BASE_ROUTE {
  MAGIC_LINK_CONFIRM = "login/magic-link-confirm",
  LOGIN_MAGIC_LINK = "login/magic-link",
  CAR_PAGE = "car/:id",
  CARS_PAGE = "cars",
  MAP = "map",
  PAYMENT_VIPPS_CALLBACK = "payment-vipps-callback",
  PAYMENT_VIPPS_FALLBACK = "payment-vipps-fallback/:orderId",
  PROFILE_PAGE = "profile",
  LOG_IN_EMAIL_AND_PASSWORD = "login/email-and-password",
  TRIPS_DASHBOARD = "trips",
  PAYMENT_TERMS = "betalingsvilkar",
  RESET_PASSWORD = "reset-password",
  FORGET_PASSWORD = "forget-password",
  NEW_USER = "new-user",
}
export enum ROUTE {
  HOME = "/",
  // Home routes (MyUser)
  CAR_PAGE = "/h/car/:id",
  CARS_PAGE = "/h/cars",
  MAP = "/h/map",
  PROFILE_PAGE = "/h/profile",
  TRIPS_DASHBOARD = "/h/trips",
  // Utility Routes
  MAGIC_LINK_CONFIRM = "/_/login/magic-link-confirm",
  LOGIN_MAGIC_LINK = "/_/login/magic-link",
  PAYMENT_VIPPS_CALLBACK = "/payment-vipps-callback",
  PAYMENT_VIPPS_FALLBACK = "/payment-vipps-fallback",
  LOG_IN_EMAIL_AND_PASSWORD = "/_/login/email-and-password",
  PAYMENT_TERMS = "/betalingsvilkar",
  RESET_PASSWORD = "/reset-password",
  FORGET_PASSWORD = "/forget-password",
  NEW_USER = "/new-user",
}

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTE.HOME} element={<App />}>
          <Route index element={<AppAuthenticationPipe />} />

          <Route path="h" element={<AppHomeLoaderPipe />}>
            <Route path={BASE_ROUTE.CARS_PAGE} element={<CarsPage />} />
            <Route path={BASE_ROUTE.CAR_PAGE} element={<CarPage />} />
            <Route path={BASE_ROUTE.MAP} element={<MapPage />} />
            <Route path={BASE_ROUTE.PROFILE_PAGE} element={<ProfilePage />} />
            <Route
              path={BASE_ROUTE.TRIPS_DASHBOARD}
              element={<TripsDashboard />}
            />
          </Route>

          <Route path="">
            <Route
              path={BASE_ROUTE.PAYMENT_TERMS}
              element={<PaymentTermsPage />}
            />
            <Route
              path={BASE_ROUTE.RESET_PASSWORD}
              element={<ResetPassword />}
            />

            <Route
              path={BASE_ROUTE.FORGET_PASSWORD}
              element={<ForgetPasswordPage />}
            />

            <Route path={BASE_ROUTE.NEW_USER} element={<CreateNewUserPage />} />
          </Route>

          <Route path="_" element={<Outlet />}>
            <Route
              path={BASE_ROUTE.PAYMENT_VIPPS_CALLBACK}
              element={<ThankYouPage />}
            />
            <Route
              path={BASE_ROUTE.PAYMENT_VIPPS_FALLBACK}
              element={<PaymentFallbackPage />}
            />
            <Route
              path={BASE_ROUTE.LOGIN_MAGIC_LINK}
              element={<LoginMagicLinkPage />}
            />
            <Route
              path={BASE_ROUTE.MAGIC_LINK_CONFIRM}
              element={<LoginMagicCallback />}
            />

            <Route
              path={BASE_ROUTE.LOG_IN_EMAIL_AND_PASSWORD}
              element={<LoginEmailAndPasswordPage />}
            />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
