import { styled, Typography, Box } from "@mui/material";
import { TripState } from "types/user";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MovingIcon from "@mui/icons-material/Moving";
import Co2OutlinedIcon from "@mui/icons-material/Co2Outlined";
import ParkOutlinedIcon from "@mui/icons-material/ParkOutlined";
import { CarState } from "types/cars";
import { emissionToTrees, tripEmission } from "libraries/emission";

type Props = {
  localCommuteFrequency?: number;
  trip: TripState;
  tripCar: CarState;
};

const RowBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
}));

const TripCardItems = ({ localCommuteFrequency, trip, tripCar }: Props) => {
  const commuteFrequency = localCommuteFrequency ?? trip.commuteFrequency;

  const tripTreesCount = emissionToTrees({
    distanceMeters: trip.distanceMeters,
    emissionType: tripCar.emissionType,
    gramEmission: tripCar.emissionCO2,
  });

  const { totalKgEmission } = tripEmission(tripCar, trip.distanceMeters);

  return (
    <>
      <RowBox>
        <CalendarMonthOutlinedIcon fontSize="small" />
        <Typography variant="body2" component="p">
          Date: {trip.tripTimeStamp}
        </Typography>
      </RowBox>
      <RowBox>
        <LocationOnOutlinedIcon fontSize="small" />
        <Typography variant="body2" component="p">
          From: {trip.fromAddress}
        </Typography>
      </RowBox>
      <RowBox>
        <LocationOnOutlinedIcon fontSize="small" />
        <Typography variant="body2" component="p">
          To: {trip.toAddress}
        </Typography>
      </RowBox>
      <RowBox>
        <MovingIcon fontSize="small" />
        <Typography variant="body2" component="p">
          Distance:
          {((trip.distanceMeters / 1000) * commuteFrequency).toFixed(2)}
          KM
        </Typography>
      </RowBox>
      <RowBox>
        <Co2OutlinedIcon fontSize="small" />
        <Typography variant="body2" component="p">
          Kg CO<sub>2</sub>: {(totalKgEmission * commuteFrequency).toFixed(2)}
        </Typography>
      </RowBox>
      <RowBox>
        <ParkOutlinedIcon fontSize="small" />
        <Typography variant="body2">
          Rainforest trees needed to <br /> absorb the CO2 :
          {(tripTreesCount * commuteFrequency).toFixed(2)} trees
        </Typography>
      </RowBox>
    </>
  );
};

export default TripCardItems;
