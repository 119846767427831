import React, { useState, useCallback } from "react";
import {
  Stack,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Button,
} from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import EmailIcon from "@mui/icons-material/Email";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "graphql/queries";
import useDisplayMessage from "hooks/useDisplayMessage";

import { AppContainer } from "../base-app/App";
import AppTopBar from "../base-app/AppTopBar";
import { getAuthAlertMessage } from "libraries/auth";

const ForgetPasswordPage = () => {
  const [email, setEmail] = useState("");

  const [resetPassword] = useMutation(RESET_PASSWORD);
  const { displayMessage } = useDisplayMessage();

  const handleSubmit = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const domain = "@rainforest.no";

    const isValidEmail =
      emailPattern.test(email) && domain.endsWith("@rainforest.no");

    if (isValidEmail) {
      const { data } = await resetPassword({ variables: { email: email } });

      if (data) {
        const userMessage = getAuthAlertMessage(data.resetPassword);

        displayMessage(userMessage.message, userMessage.type);
      }
    }
  };

  const handleEmailChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setEmail(event.target.value),
    []
  );

  return (
    <>
      <AppTopBar icon={<VpnKeyIcon color="primary" />} title="Sign in" />
      <AppContainer>
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{ mt: 2 }}
          component="form"
        >
          <Stack gap={2} mb={2}>
            <FormControl variant="outlined">
              <OutlinedInput
                id="email-input"
                value={email}
                onChange={handleEmailChange}
                endAdornment={
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                }
                aria-describedby="email-input"
                inputProps={{
                  "aria-label": "email",
                  type: "email",
                }}
              />
              <FormHelperText id="email-input">
                Email to reset password
              </FormHelperText>
            </FormControl>
          </Stack>
          <Button onClick={handleSubmit}>Send reset link to my email</Button>
        </Grid>
      </AppContainer>
    </>
  );
};

export default ForgetPasswordPage;
