import { TripEmission, CarState } from "../types/cars";

export const metersToKm = (meters: number) => meters / 1000;

const KG_CO2_SAVED_PER_TREE = 25;
const ELECTRIC_NORWAY_CARBON_INTENSITY_CO2GRAM_PER_KWH = 29;

/**
 * KiloEmission - Car Type [Gas]
 */
const gasEmissionKiloC02 = (gramEmission: number, distanceMeters: number) => {
  // Convert distance from meters to kilometers
  const distanceKm = distanceMeters / 1000;

  // Total CO₂ emissions in grams for the distance (g/km * km)
  const totalEmissionsGrams = gramEmission * distanceKm;

  // Convert grams to kilograms
  const totalEmissionsKg = totalEmissionsGrams / 1000;

  return totalEmissionsKg; // Emissions in kilograms
};

/**
 * KiloEmission - Car Type [Electric]
 */
export const electricEmissionKiloCo2 = (
  gramEmission: number,
  distanceMeters: number
) => {
  const distanceKm = distanceMeters / 1000;

  // Convert gramEmission (Wh/km) to kWh/km by dividing by 1000
  const energyConsumptionKWhPerKm = gramEmission / 1000;

  // Total energy consumption in kWh for the given distance
  const totalEnergyConsumptionKWh = energyConsumptionKWhPerKm * distanceKm;

  // Total CO₂ emissions in grams (g CO₂ = kWh * intensity)
  const totalEmissionsGrams =
    totalEnergyConsumptionKWh *
    ELECTRIC_NORWAY_CARBON_INTENSITY_CO2GRAM_PER_KWH;

  // Convert grams to kilograms
  const totalEmissionsKg = totalEmissionsGrams / 1000;

  return totalEmissionsKg; // Emissions in kilograms
};

/**
 * KiloEmission - Car Type [Any]
 */
const emissionKiloCo2 = ({
  gramEmission,
  emissionType,
  distanceMeters,
}: {
  gramEmission: number;
  emissionType: string;
  distanceMeters: number;
}) => {
  const emissionsKG =
    emissionType === "electric"
      ? electricEmissionKiloCo2(gramEmission, distanceMeters)
      : gasEmissionKiloC02(gramEmission, distanceMeters);

  return emissionsKG;
};
/**
 * Convert gramEmission to Trees saved
 */
export const emissionToTrees = ({
  distanceMeters,
  emissionType,
  gramEmission,
}: {
  distanceMeters: number;
  emissionType: string;
  gramEmission: number;
}): number => {
  // Calculate the total emissions in kg using the distance
  const emissionsInKg = emissionKiloCo2({
    gramEmission,
    emissionType,
    distanceMeters,
  });

  // Calculate the number of trees needed to offset the total emissions
  return emissionsInKg / KG_CO2_SAVED_PER_TREE;
};
export const tripEmission = (
  car: CarState | null,
  distanceMeters: number
): TripEmission => {
  const emissionType = car?.emissionType ?? "";
  const gramEmission = car?.emissionCO2 ?? 0;

  const totalKgEmission = emissionKiloCo2({
    gramEmission,
    emissionType,
    distanceMeters,
  });

  const totalTrees = emissionToTrees({
    distanceMeters,
    emissionType,
    gramEmission,
  });

  return { distanceMeters, totalTrees, totalKgEmission };
};
