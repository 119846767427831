import { useState, useRef, useCallback } from "react";
import { add, debounce } from "lodash/fp";
import { TripState } from "types/user";
import useSaveFrequencyToTrip from "hooks/trips/useSaveFrequencyToTrip";
import { updateMyUserTrips } from "redux/slices/shed/shedSlice";
import { useAppDispatch } from "redux/hooks";

type Props = {
  trip: TripState;
};

type ReturnType = {
  commuteFrequency: number;
  increaseFrequency: () => void;
  decreaseFrequency: () => void;
};

const DEBOUNCE_FREQUENCY_CHANGE = 1000;

const useNetworkTripCommuteFrequency = ({ trip }: Props): ReturnType => {
  const dispatch = useAppDispatch();

  const { handleSaveFrequencyToTrip } = useSaveFrequencyToTrip({ trip });
  const frequencyToSave = useRef(trip.commuteFrequency ?? 1);
  const [localFrequency, setLocalFrequency] = useState(
    trip.commuteFrequency ?? 1
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSaveTrip = useCallback(
    debounce(DEBOUNCE_FREQUENCY_CHANGE, () =>
      handleSaveFrequencyToTrip(frequencyToSave.current)
    ),
    []
  );

  const handleIncreaseFrequency = useCallback(() => {
    frequencyToSave.current += 1;

    setLocalFrequency(add(1));
    dispatch(
      updateMyUserTrips({
        tripId: trip.tripId,
        fromLatitude: trip.fromLatitude,
        fromLongitude: trip.fromLongitude,
        toLatitude: trip.toLatitude,
        toLongitude: trip.toLongitude,
        fromAddress: trip.fromAddress,
        toAddress: trip.toAddress,
        commuteFrequency: frequencyToSave.current,
      })
    );

    debouncedSaveTrip();
  }, [debouncedSaveTrip, dispatch, trip]);

  const handleDecreaseFrequency = useCallback(() => {
    frequencyToSave.current -= 1;

    setLocalFrequency(add(-1));
    dispatch(
      updateMyUserTrips({
        tripId: trip.tripId,
        fromLatitude: trip.fromLatitude,
        fromLongitude: trip.fromLongitude,
        fromAddress: trip.fromAddress,

        toLatitude: trip.toLatitude,
        toLongitude: trip.toLongitude,
        toAddress: trip.toAddress,
        commuteFrequency: frequencyToSave.current,
      })
    );
    debouncedSaveTrip();
  }, [debouncedSaveTrip, dispatch, trip]);

  return {
    commuteFrequency: localFrequency,
    increaseFrequency: handleIncreaseFrequency,
    decreaseFrequency: handleDecreaseFrequency,
  };
};

export default useNetworkTripCommuteFrequency;
