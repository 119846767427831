import { useState, useEffect, useContext } from "react";
import { MapContext } from "../../providers/MapProvider";

const MapMarker = (options: google.maps.MarkerOptions) => {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const { maps, initialized } = useContext(MapContext);

  useEffect(() => {
    if (!(maps && initialized)) {
      return;
    }

    marker || setMarker(new maps.Marker());

    // remove marker from map on unmount
    return () => {
      marker && marker.setMap(null);
    };
  }, [marker, maps, initialized]);

  useEffect(() => {
    marker && marker.setOptions(options);
  }, [marker, options]);

  return null;
};

export default MapMarker;
