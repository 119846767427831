import React from "react";
import CarDetailsButton from "./CarDetailsButton";
import CarLogoBrand from "./CarLogoBrand";
import { Box } from "@mui/material";
import { CarState } from "../../types/cars";

type Props = {
  car: CarState;
};

const CarOverview = ({ car }: Props) => (
  <Box textAlign="center" maxWidth="100">
    <CarLogoBrand size="large" carBrand={car.carBrand} />
    <Box mt={1}>
      <CarDetailsButton label={car.carBrand} car={car} />
    </Box>
  </Box>
);

export default CarOverview;
