import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type RainForestPopupInfo = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

function RainforestPopupInfo({ open, setOpen }: RainForestPopupInfo) {
  const handleClose = (open: any) => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Important information"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            About the app: The intention is to help you rethink and understand
            CO2 climate impact in your daily life, and then transparently
            provide insight that is easy to act on.
            <br />
            <br />
            Saving the worlds Rainforest is the easiest and cheapest way to
            reduce carbon emissions globally. Therefore we show how CO2 released
            into the atmosphere by driving, relates to trees that must be
            protected to make real environmental impact.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Lukk
          </Button>
        </DialogActions>
      </Dialog>

      {/* Other content that is shown when the dialog is closed */}
      {!open && (
        <div>
          <h1>Welcome to the rest of the application!</h1>
          <p>Here you can interact with other parts of the application.</p>
        </div>
      )}
    </div>
  );
}

export default RainforestPopupInfo;
