import { IconButton, Collapse, styled, Box, Button } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CollapseToolbar = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
});

type Props = {
  isMinimized: boolean;
  onToggle: () => void;
  onClearTrip: () => void;
  children: React.ReactElement;
};

const MapPanelCollapse = ({
  children,
  onClearTrip,
  isMinimized,
  onToggle,
}: Props) => (
  <>
    <CollapseToolbar>
      <Button startIcon={<MapIcon />} onClick={onClearTrip}>
        New Trip
      </Button>
      {isMinimized ? (
        <IconButton onClick={onToggle}>
          <ExpandMoreIcon />
        </IconButton>
      ) : (
        <IconButton onClick={onToggle}>
          <ExpandLessIcon />
        </IconButton>
      )}
    </CollapseToolbar>
    <Collapse in={!isMinimized}>{children}</Collapse>
  </>
);

export default MapPanelCollapse;
