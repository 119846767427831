import { useEffect, EffectCallback } from "react";

type Props = {
  handler: EffectCallback;
};

const usePostMount = ({ handler }: Props) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handler(), []);
};

export default usePostMount;
