import { Stack, Skeleton } from "@mui/material";

const TripOverviewSkeleton = () => (
  <Stack alignItems="center" width={80}>
    <Skeleton variant="circular" width={40} height={40} />
    <Skeleton variant="rectangular" sx={{ mt: 1 }} width="100%" height={32} />
  </Stack>
);

export default TripOverviewSkeleton;
