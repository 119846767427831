import { useMemo } from "react";
import { useAppSelector } from "../../redux/hooks";
import { selectReferenceTrip } from "../../redux/slices/shed/shedSlice";

type Props = {
  label: "From" | "To";
  locationText: string | null;
};

const isPristine = (locationText: string | null) => locationText === null;

const useMapLocationDisplay = ({ label, locationText }: Props): string => {
  const referenceTrip = useAppSelector(selectReferenceTrip);

  return useMemo(() => {
    if (!referenceTrip) {
      return locationText || "";
    }

    const { fromAddress, toAddress } = referenceTrip;

    return label === "From" && fromAddress && isPristine(locationText)
      ? fromAddress
      : label === "To" && toAddress && isPristine(locationText)
      ? toAddress
      : !isPristine(locationText)
      ? locationText || ""
      : label === "From"
      ? locationText || fromAddress || ""
      : label === "To"
      ? locationText || toAddress || ""
      : "";
  }, [referenceTrip, label, locationText]);
};

export default useMapLocationDisplay;
