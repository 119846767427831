import React from "react";
import {
  from as ApolloFrom,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  GraphQLRequest,
  Operation,
  NextLink,
} from "@apollo/client";

import { STORAGE_KEY_AUTH_TOKEN } from "../components/base-app/LoginMagicCallback";

export const GraphqlContext = React.createContext<Object>({});

// https://www.apollographql.com/docs/react/api/link/introduction
// https://community.apollographql.com/t/refreshing-access-and-refresh-tokens-via-apollo-in-react/1440/5

const httpTerminattingLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authDownLink = (_: GraphQLRequest, prevContext: any | undefined) => {
  // get the authentication token from local storage if it exists
  console.log("[Provider] Graphql - AuthLink Down - Add authorization header");
  const token = localStorage.getItem(STORAGE_KEY_AUTH_TOKEN);
  // return the headers to the context so httpLink can read them
  return {
    ...prevContext,
    headers: {
      ...prevContext?.headers,
      ...(Boolean(token) && { authorization: `Bearer ${token}` }),
    },
  };
};

const authUpLink = (response: any, operation: Operation, forward: NextLink) => {
  console.log("[Provider] Graphql - authUpLink");
  return response;
};

const authLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  // Called before operation is sent to server (down)
  operation.setContext(authDownLink);
  // Called after server responds (up)
  return forward(operation).map((response: any) =>
    authUpLink(response, operation, forward)
  );
});

const EMPTY_OBJECT = {};

type Props = {
  children: React.ReactElement;
};

const client = new ApolloClient({
  link: ApolloFrom([authLink, httpTerminattingLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all",
    },
  },
});

const GraphqlProvider = ({ children }: Props) => (
  <GraphqlContext.Provider value={EMPTY_OBJECT}>
    <ApolloProvider client={client}>{children}</ApolloProvider>
  </GraphqlContext.Provider>
);

export default GraphqlProvider;
