import { useState } from "react";
import { CONFIRM_PASSWORD_RESET } from "graphql/queries";
import { useMutation } from "@apollo/client";
import {
  Box,
  FormControl,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  Button,
} from "@mui/material";
import PasswordIcon from "@mui/icons-material/Password";

const ResetPassword = () => {
  const [confirmPasswordReset] = useMutation(CONFIRM_PASSWORD_RESET);
  const [password, setPassword] = useState("");

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const confirmPasswordChange = async () => {
    const urlParams = new URLSearchParams(window.location.search);

    const mode = urlParams.get("mode");
    const oobCode = urlParams.get("oobCode");

    if (mode === "resetPassword" && oobCode) {
      const confirmPasswordResponse = await confirmPasswordReset({
        variables: {
          oobCode: oobCode,
          newPassword: password,
        },
      });

      console.log(confirmPasswordResponse);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <FormControl variant="outlined">
        <OutlinedInput
          id="reset-password-input"
          value={password}
          onChange={handlePasswordChange}
          endAdornment={
            <InputAdornment position="start">
              <PasswordIcon />
            </InputAdornment>
          }
          aria-describedby="reset-password-input"
          inputProps={{
            "aria-label": "password",
            type: "password",
          }}
        />
        <FormHelperText id="reset-password-input">
          Set new password
        </FormHelperText>
      </FormControl>
      <Button onClick={confirmPasswordChange}>SUBMIT</Button>
    </Box>
  );
};

export default ResetPassword;
