import { Outlet } from "react-router-dom";
import { GET_MY_USER } from "../../graphql/queries";
import useAuthQuery from "../../hooks/useAuthQuery";
import { MyUserState } from "../../types/user";
import { useAppDispatch } from "../../redux/hooks";
import { loadUserHomeData } from "../../redux/slices/shed/shedSlice";

const AppHomeLoaderPipe = () => {
  const dispatch = useAppDispatch();
  useAuthQuery<{ myUser: MyUserState }>(GET_MY_USER, {
    onCompleted: (myUserData) => {
      console.log("[AppHome] Loader user data completed", myUserData);
      myUserData &&
        myUserData.myUser &&
        dispatch(loadUserHomeData(myUserData.myUser));
    },
  });

  return <Outlet />;
};

export default AppHomeLoaderPipe;
