import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import { omit } from "lodash";

import {
  addUserCar,
  registerReferenceCar,
} from "../redux/slices/shed/shedSlice";
import { useAppDispatch } from "redux/hooks";
import { SAVE_CAR } from "graphql/queries";
import { CarState } from "types/cars";
import useDisplayMessage from "./useDisplayMessage";

type Props = {
  carToSave: CarState | null;
  onSaved: (addedCar: CarState) => void;
};

type ReturnType = {
  loading: boolean;
  loadingError: boolean;
  handleSaveCar: () => void;
};

const useCarSave = ({ carToSave, onSaved }: Props): ReturnType => {
  const dispatch = useAppDispatch();
  const { displayMessage } = useDisplayMessage();

  const [saveCar, { loading, error }] = useMutation(SAVE_CAR);

  const handleSaveCar = useCallback(async () => {
    if (!carToSave?.licensePlate && !carToSave?.genericVin) {
      displayMessage("Add a car to your trip", "error");
      console.warn(
        "[useCarSave] Saved car control no license Plate or genericVin"
      );
      return;
    }

    if (carToSave.id) {
      onSaved(carToSave);

      return;
    }

    try {
      const { data } = await saveCar({
        variables: { car: omit(carToSave, ["id"]) },
      });

      data.addCar && console.log("[useCarSave] Car saved", data);
      data.addCar && dispatch(addUserCar(data.addCar));
      data.addCar && onSaved(data.addCar);
      data.addCar && dispatch(registerReferenceCar(data.addCar));

      !data.addCar && console.log("[useCarSave] Car not saved", data);

      displayMessage(`Trip Saved`, `success`);
    } catch (error) {
      console.error("[useCarSave] Network Error Saving car", error);
    }
  }, [onSaved, carToSave, saveCar, dispatch, displayMessage]);

  return {
    handleSaveCar,
    loading,
    loadingError: Boolean(error),
  };
};

export default useCarSave;
