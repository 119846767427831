import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box, Button, styled, Typography } from "@mui/material";
import { AppContainer } from "../base-app/App";
import { ROUTE } from "components/base-app/AppRoutes";

const StyledImg = styled("img")`
  margin-left: "auto";
  margin-right: "auto";
  width: 80%;
  height: 80%;
  max-width: 200px;
`;

const PaymentFallbackPage = () => (
  <AppContainer sx={{ textAlign: "center" }}>
    <Box textAlign="center" maxWidth="100">
      <StyledImg
        alt="brand-logo"
        src={`${process.env.PUBLIC_URL}/logo_NO_horizontal_black.svg`}
      />
    </Box>
    <Box my={2}>
      <Typography variant="h5">Thank you for your donation.</Typography>
      <Typography variant="body2" color="text.secondary">
        You have donated money that will save or plant trees
      </Typography>
    </Box>
    <Button
      startIcon={<AccountCircleIcon />}
      variant="contained"
      component={Link}
      to={ROUTE.PROFILE_PAGE}
      color="success"
    >
      Your Forest
    </Button>
  </AppContainer>
);

export default PaymentFallbackPage;
