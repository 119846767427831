import { Observable } from "@apollo/client";

export const promiseToObservable = (promise: Promise<any>) =>
  new Observable((subscriber: any) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
    return subscriber; // this line can removed, as per next comment
  });

export const sleep = (ms: number) => new Promise((x) => setTimeout(x, ms));
