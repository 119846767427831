import { useState, useCallback } from "react";
import { omit } from "lodash"; // Assuming you're using lodash for omit and debounce
import { sleep } from "libraries/async";
import { SEARCH_CAR } from "../graphql/queries";
import { useLazyQuery } from "@apollo/client";
import { CarState } from "../types/cars";
import { registerReferenceCar } from "../redux/slices/shed/shedSlice";
import { useAppDispatch } from "../redux/hooks";

type ReturnType = {
  handleSearchCar: (licensePlate: string) => void;
  smoothLoading: boolean;
  queryLoading: boolean;
};

type CarQuery = {
  searchCar: CarState | null;
};

const useCarSearch = (): ReturnType => {
  const dispatch = useAppDispatch();
  const [smoothLoading, setSmoothLoading] = useState<boolean>(false);

  const onQueryCompleted = useCallback(
    async ({ searchCar }: CarQuery) => {
      if (searchCar) {
        const currentCar = omit(searchCar, ["__typename"]) as CarState;

        dispatch(registerReferenceCar(currentCar));
      } else {
        console.log("No Car Exist");
      }

      await sleep(1000);
      setSmoothLoading(false);
    },
    [dispatch]
  );

  const [searchCar, { loading: queryLoading }] = useLazyQuery<CarQuery>(
    SEARCH_CAR,
    {
      fetchPolicy: "network-only",
      onCompleted: onQueryCompleted,
    }
  );

  const handleSearchCar = useCallback(
    async (licensePlate: string) => {
      // Checks if licenseplate is of format of 2 letters and 5 numbers "UF48267"
      const validFormat = /^[A-Z]{2}\d{5}$/i.test(licensePlate);

      licensePlate && validFormat && setSmoothLoading(true);

      validFormat &&
        licensePlate &&
        (await searchCar({ variables: { licensePlate } }));
    },
    [setSmoothLoading, searchCar]
  );

  return { handleSearchCar, smoothLoading, queryLoading };
};

export default useCarSearch;
