import React from "react";
import styled from "@emotion/styled";

import { Fab, Box } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import { Add as AddIcon } from "@mui/icons-material";
import { BottomBarPlaceholder } from "../base-app/BottomBar";

const FabContainer = styled.div`
  position: fixed;
  flex-wrap: wrap;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FabBox = muiStyled(Box)(`
  flex-basis: 100%;
  display: flex;
  justify-content: center;
`);

type Props = {
  onClick: () => void;
};

const CarsFab = ({ onClick }: Props) => (
  <FabContainer>
    <FabBox mb={1}>
      <Fab variant="extended" color="secondary" onClick={onClick}>
        <AddIcon sx={{ mr: 1 }} />
        Add Car
      </Fab>
    </FabBox>
    <BottomBarPlaceholder />
  </FabContainer>
);

export default CarsFab;
