import {
  useState,
  useCallback,
  createContext,
  ReactNode,
  useMemo,
} from "react";
import { AlertColor, Alert, Snackbar } from "@mui/material";
import { noop } from "lodash/fp";

type MapContext = {
  displayAlert: (
    message: string,
    severity: "error" | "info" | "success" | "warning"
  ) => void;
};

const contextDefaultValue: MapContext = {
  displayAlert: noop,
};

export const AlertContext = createContext<MapContext>(contextDefaultValue);

const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<AlertColor>("info");

  const clearMessage = useCallback(() => setMessage(""), []);

  const handleDisplayAlert = useCallback(
    (message: string, severity: "error" | "info" | "success" | "warning") => {
      setMessage(message);
      setSeverity(severity);
    },
    []
  );

  const contextValue = useMemo(
    () => ({
      displayAlert: handleDisplayAlert,
    }),
    [handleDisplayAlert]
  );

  return (
    <AlertContext.Provider value={contextValue}>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={6000}
        onClose={clearMessage}
      >
        <Alert
          onClose={clearMessage}
          severity={severity}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
